var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Button, Modal, ModalDialog, Stack, Typography, ModalClose, IconButton, Snackbar, Dropdown, MenuButton, Menu, MenuItem, Select, Option, } from "@mui/joy";
import { useColorScheme } from "@mui/joy/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useTwilio } from "../../data/hooks/useTwilio";
import ConfirmationModal from "../../components/ui/ConfirmationModal";
import MoreVert from "@mui/icons-material/MoreVert";
var ActionDropdown = function (_a) {
    var numberId = _a.numberId, onRelease = _a.onDelete;
    return (_jsxs(Dropdown, { children: [_jsx(MenuButton, __assign({ slots: { root: IconButton }, slotProps: { root: { variant: "plain", color: "neutral" } } }, { children: _jsx(MoreVert, {}) })), _jsx(Menu, __assign({ placement: "bottom-end", size: "sm" }, { children: _jsxs(MenuItem, __assign({ onClick: function () { return onRelease(numberId); }, variant: "soft", color: "danger" }, { children: [_jsx(DeleteIcon, { sx: { mr: 1 } }), "Release"] })) }))] }));
};
/**
 * This component manages phone numbers:
 * - Displays owned phone numbers (local DB)
 * - Allows releasing (delete)
 * - Modal to list/buy new phone numbers from Twilio
 */
export var ManagePhoneNumbers = function () {
    var mode = useColorScheme().mode;
    var _a = useState(true), darkMode = _a[0], setDarkMode = _a[1];
    // Hooks from Twilio logic
    var _b = useTwilio(), listOwnedPhoneNumbers = _b.listOwnedPhoneNumbers, listAvailablePhoneNumbers = _b.listAvailablePhoneNumbers, purchasePhoneNumber = _b.purchasePhoneNumber, deletePhoneNumber = _b.deletePhoneNumber;
    // Owned phone numbers (local DB)
    var _c = useState([]), ownedNumbers = _c[0], setOwnedNumbers = _c[1];
    var _d = useState(false), loadingOwned = _d[0], setLoadingOwned = _d[1];
    // Modal for "Buy New Number"
    var _e = useState(false), openModal = _e[0], setOpenModal = _e[1];
    // Available phone numbers from Twilio
    var _f = useState([]), availableNumbers = _f[0], setAvailableNumbers = _f[1];
    var _g = useState(false), loadingAvailable = _g[0], setLoadingAvailable = _g[1];
    // Basic filters for available phone numbers
    var _h = useState("US"), countryCode = _h[0], setCountryCode = _h[1];
    var _j = useState(30), limit = _j[0], setLimit = _j[1];
    // Alert messages
    var _k = useState(null), errorMessage = _k[0], setErrorMessage = _k[1];
    var _l = useState(null), successMessage = _l[0], setSuccessMessage = _l[1];
    var _m = useState(null), confirmation = _m[0], setConfirmation = _m[1];
    // Fetch owned phone numbers on mount
    useEffect(function () {
        fetchOwnedNumbers();
    }, []);
    // Keep track of dark/light mode from Joy UI
    useEffect(function () {
        setDarkMode(mode === "dark");
    }, [mode]);
    // Owned numbers
    var fetchOwnedNumbers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingOwned(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, listOwnedPhoneNumbers()];
                case 2:
                    data = _a.sent();
                    setOwnedNumbers(data);
                    return [3 /*break*/, 5];
                case 3:
                    error_1 = _a.sent();
                    console.error(error_1);
                    setErrorMessage("Error fetching owned phone numbers.");
                    return [3 /*break*/, 5];
                case 4:
                    setLoadingOwned(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // Available numbers
    var fetchAvailableNumbers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var data, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingAvailable(true);
                    setErrorMessage(null);
                    setSuccessMessage(null);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, listAvailablePhoneNumbers(countryCode, limit)];
                case 2:
                    data = _a.sent();
                    setAvailableNumbers(data);
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.error(error_2);
                    setErrorMessage("Error fetching available phone numbers.");
                    return [3 /*break*/, 5];
                case 4:
                    setLoadingAvailable(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // Buy phone number
    var handleBuyNumber = function (phoneNumber) {
        setConfirmation({
            message: "Buy phone number: ".concat(phoneNumber, "?"),
            onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                var error_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, purchasePhoneNumber(phoneNumber)];
                        case 1:
                            _a.sent();
                            setSuccessMessage("Successfully purchased ".concat(phoneNumber, "."));
                            fetchOwnedNumbers();
                            setOpenModal(false);
                            setAvailableNumbers([]);
                            // Ensure the modal is closed/unmounted
                            setConfirmation(null);
                            return [3 /*break*/, 3];
                        case 2:
                            error_3 = _a.sent();
                            setErrorMessage((error_3 === null || error_3 === void 0 ? void 0 : error_3.response.data) || "Error purchasing phone number.");
                            setConfirmation(null);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); },
        });
    };
    var handleRelease = function (localId) {
        setConfirmation({
            message: "Are you sure you want to release this number?",
            onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                var error_4;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, deletePhoneNumber(localId)];
                        case 1:
                            _a.sent();
                            setSuccessMessage("Phone number released successfully.");
                            fetchOwnedNumbers();
                            return [3 /*break*/, 3];
                        case 2:
                            error_4 = _a.sent();
                            setErrorMessage("Error releasing phone number.");
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); },
        });
    };
    // Grid definitions for owned phone numbers (local DB)
    var defaultColDef = useMemo(function () { return ({
        filter: true,
        editable: false,
        resizable: true,
    }); }, []);
    var ownedCols = [
        { headerName: "Phone Number", field: "e164PhoneNumber", flex: 2 },
        { headerName: "Friendly Name", field: "friendlyName", flex: 2 },
        {
            headerName: "Actions",
            field: "actions",
            width: 120,
            cellRenderer: function (params) { return (_jsx(Box, __assign({ sx: {
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                } }, { children: _jsx(ActionDropdown, { numberId: params.data.id, onDelete: handleRelease }) }))); },
        },
    ];
    // Grid definitions for available phone numbers (matches API model)
    var availableCols = [
        { headerName: "Phone Number", field: "phoneNumber", flex: 2 },
        { headerName: "Region", field: "region", flex: 1 },
        { headerName: "Rate Center", field: "rateCenter", flex: 1 },
        {
            headerName: "Actions",
            field: "actions",
            width: 100,
            cellRenderer: function (params) { return (_jsx(Button, __assign({ variant: "soft", color: "primary", size: "sm", onClick: function () { return handleBuyNumber(params.data.phoneNumber); } }, { children: "Buy" }))); },
        },
    ];
    return (_jsxs(_Fragment, { children: [confirmation && (_jsx(ConfirmationModal, { open: Boolean(confirmation), message: confirmation.message, onConfirm: confirmation.onConfirm, onCancel: function () { return setConfirmation(null); } })), _jsx(Snackbar, __assign({ variant: "soft", color: "danger", open: errorMessage !== null, onClose: function () { return setErrorMessage(null); }, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 6000, startDecorator: _jsx(ErrorIcon, {}), endDecorator: _jsx(Button, __assign({ onClick: function () { return setErrorMessage(null); }, size: "sm", variant: "soft", color: "danger" }, { children: "Dismiss" })) }, { children: errorMessage })), _jsx(Snackbar, __assign({ variant: "soft", color: "success", open: successMessage != null, onClose: function () { return setSuccessMessage(null); }, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 6000, startDecorator: _jsx(DoneIcon, {}), endDecorator: _jsx(Button, __assign({ onClick: function () { return setSuccessMessage(null); }, size: "sm", variant: "soft", color: "success" }, { children: "Dismiss" })) }, { children: successMessage })), _jsxs(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%" } }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", sx: { px: 2 } }, { children: [_jsx(Typography, __assign({ sx: { mt: 2 }, level: "h2", component: "h1" }, { children: "Phone Numbers" })), _jsx(Button, __assign({ variant: "solid", color: "primary", sx: { mt: "6" }, startDecorator: _jsx(AddIcon, {}), onClick: function () {
                                    setOpenModal(true), fetchAvailableNumbers();
                                } }, { children: "Buy New Number" }))] })), _jsx(Box, __assign({ sx: { mt: 1, px: 2, width: "100%", height: "100%" } }, { children: _jsx(Box, __assign({ sx: { width: "100%", height: "100%" }, className: !darkMode ? "ag-theme-quartz" : "ag-theme-quartz-dark" }, { children: _jsx(AgGridReact, { rowData: ownedNumbers, columnDefs: ownedCols, defaultColDef: defaultColDef, pagination: true, enableCellTextSelection: true }) })) })), _jsx(Modal, __assign({ open: openModal, onClose: function () { return setOpenModal(false); }, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ variant: "outlined", size: "lg", layout: "center", sx: {
                                maxWidth: "80vw",
                                minWidth: "60vw",
                                maxHeight: "90vh",
                                overflow: "auto",
                            } }, { children: [_jsx(ModalClose, {}), _jsx(Typography, __assign({ level: "h4" }, { children: "Buy a New Phone Number" })), _jsxs(Stack, __assign({ direction: "row", spacing: 2, sx: { my: 2 } }, { children: [_jsxs(Select, __assign({ sx: { minWidth: 200 }, value: countryCode, onChange: function (e, newValue) { return setCountryCode(newValue); } }, { children: [_jsx(Option, __assign({ value: "US" }, { children: "US" })), _jsx(Option, __assign({ value: "CA" }, { children: "Canada" }))] })), _jsx(Button, __assign({ onClick: fetchAvailableNumbers, disabled: loadingAvailable }, { children: loadingAvailable ? "Loading..." : "Search" }))] })), _jsx(Box, __assign({ sx: { height: "500px" }, className: !darkMode ? "ag-theme-quartz" : "ag-theme-quartz-dark" }, { children: _jsx(AgGridReact, { rowData: availableNumbers, columnDefs: availableCols, defaultColDef: defaultColDef, pagination: true, enableCellTextSelection: true }) }))] })) }))] }))] }));
};
