var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { useDashboard } from "../../data/hooks/useDashboard";
import Skeleton from "react-loading-skeleton";
import Grid from "@mui/joy/Grid";
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import AnalyticsDataGrid, { GridType } from "./components/AnalyticsDataGrid";
import { Chart as ChartJS, ArcElement, Tooltip, Legend, LinearScale, CategoryScale, BarElement, } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { GaugeContainer, useGaugeState, GaugeReferenceArc, } from "@mui/x-charts/Gauge";
import Link from "@mui/joy/Link";
import { useColorScheme } from "@mui/joy";
// ***** NEW imports for filters *****
import { Select } from "@mui/joy";
import Option from "@mui/joy/Option";
import { FilterEnums } from "../../types/enums";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../Dashboard/styles/styles.css";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels, LinearScale, CategoryScale, BarElement);
export var Dashboard = function () {
    var _a = useDashboard(), workspaceConversationsDashboardStats = _a.workspaceConversationsDashboardStats, workspaceConversationsCommonTopicsStats = _a.workspaceConversationsCommonTopicsStats, isWorkspaceConversationCommonTopicsStatsIsLoading = _a.isWorkspaceConversationCommonTopicsStatsIsLoading, workspaceConversationEscalatedStats = _a.workspaceConversationEscalatedStats, workspaceConversationKnowledgeBaseGapsStats = _a.workspaceConversationKnowledgeBaseGapsStats, workspaceConversationSentimentStats = _a.workspaceConversationSentimentStats, workspaceConversationSessionsStats = _a.workspaceConversationSessionsStats, workspaceConversationsSentimentByCommonTopicsStats = _a.workspaceConversationsSentimentByCommonTopicsStats, isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading = _a.isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading, 
    // fetchers
    getWorkspaceConversationKnowledgeBaseGapsStats = _a.getWorkspaceConversationKnowledgeBaseGapsStats, getWorkspaceEscalatedConversationStats = _a.getWorkspaceEscalatedConversationStats, getWorkspaceConversationsDashboardStats = _a.getWorkspaceConversationsDashboardStats, getWorkspaceConversationsCommonTopicsStats = _a.getWorkspaceConversationsCommonTopicsStats, getWorkspaceConversationSentimentStats = _a.getWorkspaceConversationSentimentStats, getWorkspaceConversationSessionsStats = _a.getWorkspaceConversationSessionsStats, getWorkspaceConversationsSentimentByCommonTopicsStats = _a.getWorkspaceConversationsSentimentByCommonTopicsStats, getWorkspaceConversationSessionsBySentimentFiltered = _a.getWorkspaceConversationSessionsBySentimentFiltered;
    // ---- FILTER STATE ----
    var _b = useState(FilterEnums.last12Hours), filter = _b[0], setDateRange = _b[1];
    // Store the actual selected custom range
    var _c = useState(null), customStartDate = _c[0], setCustomStartDate = _c[1];
    var _d = useState(null), customEndDate = _d[0], setCustomEndDate = _d[1];
    // For react-date-range usage
    var _e = useState(false), dateRangePickerVisible = _e[0], setShowDateRangePicker = _e[1];
    var dateRangeRef = useRef(null);
    var _f = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]), selectedRange = _f[0], setSelectedRange = _f[1];
    var mode = useColorScheme().mode;
    // Helper: Display the selected date range in the dropdown
    var displayRange = function () {
        if (!customStartDate || !customEndDate)
            return "Custom Range";
        return "".concat(customStartDate.toLocaleDateString(), " - ").concat(customEndDate.toLocaleDateString());
    };
    // Hide the date picker if clicking outside
    useEffect(function () {
        function handleClickOutside(event) {
            if (dateRangeRef.current &&
                !dateRangeRef.current.contains(event.target)) {
                setShowDateRangePicker(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    // ---- RE-FETCH on filter changes ----
    useEffect(function () {
        getWorkspaceConversationsDashboardStats(filter, customStartDate, customEndDate);
        getWorkspaceConversationsCommonTopicsStats(filter, customStartDate, customEndDate);
        getWorkspaceConversationsSentimentByCommonTopicsStats(filter, customStartDate, customEndDate);
        // ... fetch anything else you need here
    }, [filter, customStartDate, customEndDate]);
    // Helper function for background color based on percentage
    var getColor = function (theme, value, total) {
        var percentage = (value / total) * 100;
        if (percentage > 75)
            return theme.palette.success.softBg;
        if (percentage > 50)
            return theme.palette.warning.softBg;
        return theme.palette.danger.softBg;
    };
    var _g = useState(), pieData = _g[0], setPieData = _g[1];
    var _h = useState(""), activeDetailsMode = _h[0], setActiveDetailsMode = _h[1];
    var _j = useState(), chartData = _j[0], setChartData = _j[1];
    var _k = useState(), barChartHeight = _k[0], setBarChartHeight = _k[1];
    // ---------- Various "details" triggers ----------
    var handleKnowledgeBaseGapsDetailsModeOpen = function () {
        getWorkspaceConversationKnowledgeBaseGapsStats(filter, customStartDate, customEndDate);
        setActiveDetailsMode("knowledgeBaseGaps");
    };
    var handleEscalationDetailsModeOpen = function () {
        getWorkspaceEscalatedConversationStats(filter, customStartDate, customEndDate);
        setActiveDetailsMode("escalations");
    };
    var handleSentimentDetailsModeOpen = function () {
        getWorkspaceConversationSentimentStats(filter, customStartDate, customEndDate);
        setActiveDetailsMode("sentiment");
    };
    var handleSessionsDetailsModeOpen = function () {
        getWorkspaceConversationSessionsStats(filter, customStartDate, customEndDate);
        setActiveDetailsMode("sessions");
    };
    var handleSentimentFilteredDetailsModeOpen = function (topic, sentiment) {
        getWorkspaceConversationSessionsBySentimentFiltered(sentiment, topic, filter, customStartDate, customEndDate);
        setActiveDetailsMode("sessions");
    };
    var handleDetailsModalClose = function () { return setActiveDetailsMode(""); };
    // Color palette for charts
    var colorPalette = [
        "#f44336",
        "#E91E63",
        "#9C27B0",
        "#673AB7",
        "#3F51B5",
        "#2196F3",
        "#03A9F4",
        "#00BCD4",
        "#009688",
        "#4CAF50",
        "#8BC34A",
        "#CDDC39",
        "#FFEB3B",
        "#FFC107",
        "#FF9800",
        "#FF5722",
        "#795548",
        "#9E9E9E",
        "#607D8B",
        "#B2EBF2",
        "#B2DFDB",
        "#C8E6C9",
        "#DCEDC8",
        "#F0F4C3",
        "#FFF9C4",
        "#FFECB3",
        "#FFE0B2",
        "#FFCCBC",
        "#D7CCC8",
        "#F5F5F5",
    ];
    // Build Pie Data once we have Common Topics
    useEffect(function () {
        if (!isWorkspaceConversationCommonTopicsStatsIsLoading &&
            workspaceConversationsCommonTopicsStats) {
            var data = {
                labels: workspaceConversationsCommonTopicsStats.topicsData.map(function (d) { return d.topicName; }),
                datasets: [
                    {
                        data: workspaceConversationsCommonTopicsStats.topicsData.map(function (d) { return d.topicCount; }),
                        backgroundColor: workspaceConversationsCommonTopicsStats.topicsData.map(function (_, index) {
                            return colorPalette[index % colorPalette.length];
                        }),
                        borderColor: "white",
                        borderWidth: 2,
                    },
                ],
            };
            setPieData(data);
        }
    }, [
        workspaceConversationsCommonTopicsStats,
        isWorkspaceConversationCommonTopicsStatsIsLoading,
    ]);
    // Build Bar Data once we have SentimentByCommonTopics
    useEffect(function () {
        if (!isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading &&
            workspaceConversationsSentimentByCommonTopicsStats) {
            var labels = workspaceConversationsSentimentByCommonTopicsStats.map(function (item) { return item.topicName; });
            var positiveData = workspaceConversationsSentimentByCommonTopicsStats.map(function (item) {
                return item.positiveCount > 0 ? item.positiveCount : null;
            });
            var negativeData = workspaceConversationsSentimentByCommonTopicsStats.map(function (item) {
                return item.negativeCount > 0 ? item.negativeCount : null;
            });
            var neutralData = workspaceConversationsSentimentByCommonTopicsStats.map(function (item) {
                return item.neutralCount > 0 ? item.neutralCount : null;
            });
            var data = {
                labels: labels,
                datasets: [
                    {
                        label: "Positive",
                        data: positiveData,
                        backgroundColor: "rgba(75, 192, 192, 0.2)",
                        borderColor: "rgba(75, 192, 192, 1)",
                        borderWidth: 1,
                    },
                    {
                        label: "Negative",
                        data: negativeData,
                        backgroundColor: "rgba(255, 99, 132, 0.2)",
                        borderColor: "rgba(255, 99, 132, 1)",
                        borderWidth: 1,
                    },
                    {
                        label: "Neutral",
                        data: neutralData,
                        backgroundColor: "rgba(54, 162, 235, 0.2)",
                        borderColor: "rgba(54, 162, 235, 1)",
                        borderWidth: 1,
                    },
                ],
            };
            var chartHeight = labels.length * 100; // approximate vertical spacing
            setBarChartHeight(chartHeight);
            setChartData(data);
        }
    }, [
        workspaceConversationsSentimentByCommonTopicsStats,
        isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading,
    ]);
    // Simple gauge pointer
    function GaugePointer() {
        var _a = useGaugeState(), valueAngle = _a.valueAngle, outerRadius = _a.outerRadius, cx = _a.cx, cy = _a.cy;
        if (valueAngle === null) {
            return null;
        }
        var target = {
            x: cx + outerRadius * Math.sin(valueAngle),
            y: cy - outerRadius * Math.cos(valueAngle),
        };
        return (_jsxs("g", { children: [_jsx("circle", { cx: cx, cy: cy, r: 5, fill: "red" }), _jsx("path", { d: "M ".concat(cx, " ").concat(cy, " L ").concat(target.x, " ").concat(target.y), stroke: "red", strokeWidth: 3 })] }));
    }
    return (_jsxs(Box, __assign({ sx: { overflowY: "auto" } }, { children: [!activeDetailsMode && (_jsxs(Box, __assign({ sx: { pl: 3.5, pr: 4, pt: 1, width: "100%" } }, { children: [_jsx(Card, { children: _jsx(CardContent, { children: _jsx(Box, __assign({ display: "flex", flexWrap: "wrap" }, { children: _jsxs(Select, __assign({ size: "sm", value: filter, onChange: function (event, newVal) {
                                        if (!newVal)
                                            return;
                                        setDateRange(newVal);
                                        // If user selects "custom"
                                        if (newVal === FilterEnums.custom) {
                                            setShowDateRangePicker(true);
                                        }
                                        else {
                                            setShowDateRangePicker(false);
                                        }
                                    }, placeholder: "Select Date Range", sx: { minWidth: 200 } }, { children: [_jsx(Option, __assign({ value: FilterEnums.last30Minutes }, { children: "Last 30 Minutes" })), _jsx(Option, __assign({ value: FilterEnums.last1Hour }, { children: "Last Hour" })), _jsx(Option, __assign({ value: FilterEnums.last4Hours }, { children: "Last 4 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last12Hours }, { children: "Last 12 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last24Hours }, { children: "Last 24 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last48Hours }, { children: "Last 48 Hours" })), _jsx(Option, __assign({ value: FilterEnums.today }, { children: "Today" })), _jsx(Option, __assign({ value: FilterEnums.yesterday }, { children: "Yesterday" })), _jsx(Option, __assign({ value: FilterEnums.last3Days }, { children: "Last 3 Days" })), _jsx(Option, __assign({ value: FilterEnums.last7Days }, { children: "Last 7 Days" })), _jsx(Option, __assign({ value: FilterEnums.last1Month }, { children: "Last 1 Month" })), _jsx(Option, __assign({ value: FilterEnums.last3Months }, { children: "Last 3 Months" })), _jsx(Option, __assign({ value: FilterEnums.all }, { children: "All" })), _jsx(Option, __assign({ value: FilterEnums.custom, onClick: function () {
                                                setDateRange(FilterEnums.custom);
                                                setShowDateRangePicker(true);
                                            } }, { children: displayRange() }))] })) })) }) }), filter === FilterEnums.custom && dateRangePickerVisible && (_jsx(Box, __assign({ ref: dateRangeRef, sx: {
                            position: "absolute",
                            zIndex: 99999,
                            display: "inline-block",
                        } }, { children: _jsx(DateRange, { ranges: selectedRange, onChange: function (item) {
                                setSelectedRange([item.selection]);
                                setCustomStartDate(item.selection.startDate);
                                setCustomEndDate(item.selection.endDate);
                            }, moveRangeOnFirstSelection: false, editableDateInputs: true, className: mode === "dark" ? "custom-date-range-picker" : "" }) })))] }))), workspaceConversationsDashboardStats &&
                !isWorkspaceConversationCommonTopicsStatsIsLoading &&
                !isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading ? (_jsxs(Grid, __assign({ container: true, spacing: 1, padding: 2, paddingTop: 0, sx: { height: "100vh", width: "100%" } }, { children: [!activeDetailsMode && (_jsxs(Grid, __assign({ container: true, padding: 2 }, { children: [_jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Card, __assign({ variant: "outlined", orientation: "horizontal", sx: function (theme) { return ({
                                        transition: "transform 0.3s, border 0.3s",
                                        "&:hover": {
                                            borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                                            transform: "translateY(-2px)",
                                        },
                                        backgroundColor: getColor(theme, workspaceConversationsDashboardStats.resolvedCount, workspaceConversationsDashboardStats.totalSessions),
                                    }); } }, { children: _jsxs(CardContent, __assign({ sx: { textAlign: "left" } }, { children: [_jsx(Typography, { children: _jsx(Link, __assign({ onClick: handleSessionsDetailsModeOpen, overlay: true, underline: "none", sx: {
                                                        color: "text.primary",
                                                        "&.Mui-focusVisible:after": {
                                                            outlineOffset: "-4px",
                                                        },
                                                    } }, { children: "Resolved Sessions" })) }), _jsxs(Typography, __assign({ level: "h4" }, { children: [workspaceConversationsDashboardStats.resolvedCount, " /", " ", workspaceConversationsDashboardStats.totalSessions, " (", (workspaceConversationsDashboardStats.resolutionRate *
                                                        100).toFixed(2), "%)"] }))] })) })) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Card, __assign({ variant: "outlined", orientation: "horizontal", sx: function (theme) { return ({
                                        transition: "transform 0.3s, border 0.3s",
                                        "&:hover": {
                                            borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                                            transform: "translateY(-2px)",
                                        },
                                    }); } }, { children: _jsxs(CardContent, __assign({ sx: { textAlign: "left" } }, { children: [_jsx(Typography, { children: _jsx(Link, __assign({ onClick: handleEscalationDetailsModeOpen, overlay: true, underline: "none", sx: {
                                                        color: "text.primary",
                                                        "&.Mui-focusVisible:after": {
                                                            outlineOffset: "-4px",
                                                        },
                                                    } }, { children: "Escalations" })) }), _jsxs(Typography, __assign({ level: "h4" }, { children: [workspaceConversationsDashboardStats.escalations, " (", (workspaceConversationsDashboardStats.escalationRate *
                                                        100).toFixed(2), "%)"] }))] })) })) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Card, __assign({ variant: "outlined", orientation: "horizontal", sx: function (theme) { return ({
                                        transition: "transform 0.3s, border 0.3s",
                                        "&:hover": {
                                            borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                                            transform: "translateY(-2px)",
                                        },
                                    }); } }, { children: _jsx(CardContent, __assign({ sx: { textAlign: "left" } }, { children: _jsxs(Box, __assign({ display: "flex", justifyContent: "space-between", alignItems: "center" }, { children: [_jsxs(Box, { children: [_jsx(Typography, { children: _jsx(Link, __assign({ onClick: handleSentimentDetailsModeOpen, overlay: true, underline: "none", sx: {
                                                                    color: "text.primary",
                                                                    "&.Mui-focusVisible:after": {
                                                                        outlineOffset: "-4px",
                                                                    },
                                                                } }, { children: "Average Sentiment" })) }), _jsx(Typography, __assign({ level: "h4" }, { children: workspaceConversationsDashboardStats.averageSentiment }))] }), _jsx(Box, { children: _jsxs(GaugeContainer, __assign({ width: 80, height: 57, innerRadius: 35, startAngle: -110, endAngle: 110, value: (workspaceConversationsDashboardStats.averageSentiment +
                                                            1) *
                                                            50 }, { children: [_jsx(GaugeReferenceArc, { style: {
                                                                    stroke: "url(#gradient)",
                                                                    strokeWidth: 3,
                                                                } }), _jsx("defs", { children: _jsxs("linearGradient", __assign({ id: "gradient", x1: "0%", y1: "0%", x2: "100%", y2: "0%" }, { children: [_jsx("stop", { offset: "0%", style: { stopColor: "red", stopOpacity: 1 } }), _jsx("stop", { offset: "100%", style: { stopColor: "green", stopOpacity: 1 } })] })) }), _jsx(GaugePointer, {})] })) })] })) })) })) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Card, __assign({ variant: "outlined", orientation: "horizontal", sx: function (theme) { return ({
                                        transition: "transform 0.3s, border 0.3s",
                                        "&:hover": {
                                            borderColor: theme.vars.palette.primary.outlinedHoverBorder,
                                            transform: "translateY(-2px)",
                                        },
                                    }); } }, { children: _jsxs(CardContent, __assign({ sx: { textAlign: "left" } }, { children: [_jsx(Typography, { children: _jsx(Link, __assign({ onClick: handleKnowledgeBaseGapsDetailsModeOpen, overlay: true, underline: "none", sx: {
                                                        color: "text.primary",
                                                        "&.Mui-focusVisible:after": {
                                                            outlineOffset: "-4px",
                                                        },
                                                    } }, { children: "Training Needed" })) }), _jsx(Typography, __assign({ level: "h4" }, { children: workspaceConversationsDashboardStats.knowledgeBaseGaps }))] })) })) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Card, { children: _jsxs(CardContent, __assign({ style: { height: "650px", width: "100%" } }, { children: [_jsx(Typography, __assign({ level: "h4", gutterBottom: true }, { children: "Most Common Topics" })), !isWorkspaceConversationCommonTopicsStatsIsLoading &&
                                                pieData && (_jsx("div", __assign({ style: { height: "850px" } }, { children: _jsx(Pie, { data: pieData, options: {
                                                        maintainAspectRatio: false,
                                                        responsive: true,
                                                        plugins: {
                                                            datalabels: {
                                                                color: "#fff",
                                                                display: "auto",
                                                                clamp: true,
                                                                formatter: function (value, context) {
                                                                    return context.chart.data.labels[context.dataIndex];
                                                                },
                                                            },
                                                        },
                                                        onHover: function (event, chartElement) {
                                                            var target = event.native
                                                                ? event.native.target
                                                                : event.target;
                                                            target.style.cursor = chartElement[0]
                                                                ? "pointer"
                                                                : "default";
                                                        },
                                                        onClick: function (event, elem) {
                                                            if (elem.length > 0) {
                                                                handleSentimentFilteredDetailsModeOpen(pieData.labels[elem[0].index], null);
                                                            }
                                                        },
                                                    } }) })))] })) }) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Card, { children: _jsxs(CardContent, __assign({ style: {
                                            height: "950px",
                                            overflowY: "auto",
                                            width: "100%",
                                        } }, { children: [_jsx(Typography, __assign({ level: "h4", gutterBottom: true }, { children: "Sentiment by Common Topics" })), !isWorkspaceConversationsSentimentByCommonTopicsStatsIsLoading &&
                                                chartData && (_jsx("div", __assign({ style: {
                                                    height: "auto",
                                                    minHeight: barChartHeight,
                                                } }, { children: _jsx(Bar, { data: chartData, options: {
                                                        indexAxis: "y",
                                                        responsive: true,
                                                        maintainAspectRatio: false,
                                                        plugins: {
                                                            legend: {
                                                                display: true,
                                                            },
                                                        },
                                                        scales: {
                                                            x: {
                                                                stacked: true,
                                                            },
                                                            y: {
                                                                stacked: true,
                                                            },
                                                        },
                                                        onHover: function (event, chartElement) {
                                                            var target = event.native
                                                                ? event.native.target
                                                                : event.target;
                                                            target.style.cursor = chartElement[0]
                                                                ? "pointer"
                                                                : "default";
                                                        },
                                                        onClick: function (event, elem) {
                                                            if (elem.length > 0) {
                                                                handleSentimentFilteredDetailsModeOpen(chartData.labels[elem[0].index], chartData.datasets[elem[0].datasetIndex]
                                                                    .label);
                                                            }
                                                        },
                                                    } }) })))] })) }) }))] }))), activeDetailsMode === "knowledgeBaseGaps" && (_jsx(AnalyticsDataGrid, { onClose: handleDetailsModalClose, data: workspaceConversationKnowledgeBaseGapsStats, gridType: GridType.KnowledgebaseGrid })), activeDetailsMode === "escalations" && (_jsx(AnalyticsDataGrid, { onClose: handleDetailsModalClose, data: workspaceConversationEscalatedStats, gridType: GridType.Escalations })), activeDetailsMode === "sentiment" && (_jsx(AnalyticsDataGrid, { onClose: handleDetailsModalClose, data: workspaceConversationSentimentStats, gridType: GridType.SentimentGrid })), activeDetailsMode === "sessions" && (_jsx(AnalyticsDataGrid, { onClose: handleDetailsModalClose, data: workspaceConversationSessionsStats, gridType: GridType.SessionGrid }))] }))) : (
            // Loading skeleton
            _jsxs(Grid, __assign({ container: true, spacing: 2, sx: { padding: 3.5, width: "100%" } }, { children: [_jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Skeleton, { width: "100%", height: 100 }) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Skeleton, { width: "100%", height: 100 }) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Skeleton, { width: "100%", height: 100 }) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Skeleton, { width: "100%", height: 100 }) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Skeleton, { width: "100%", height: 200 }) })), _jsx(Grid, __assign({ xs: 12, md: 6 }, { children: _jsx(Skeleton, { width: "100%", height: 200 }) }))] })))] })));
};
