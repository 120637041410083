var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import { Box, Button, Snackbar, } from "@mui/joy";
import { ReactFlowProvider } from "@xyflow/react";
import { useCallFlows } from "../../../data/hooks/useCallFlows";
import FlowCanvas from "./components/FlowCanvas";
import FlowBuilderSkeleton from "./components/FlowBuilderSkeleton";
export default function FlowBuilder(_a) {
    var _this = this;
    var isCreate = _a.isCreate;
    var flowId = useParams().flowId;
    var _b = useCallFlows(), getCallFlowById = _b.getCallFlowById, createCallFlow = _b.createCallFlow, updateCallFlow = _b.updateCallFlow, assignPhoneNumbersToCallFlow = _b.assignPhoneNumbersToCallFlow;
    var _c = useState(""), currentFlowName = _c[0], setFlowName = _c[1];
    var _d = useState([]), initialNodes = _d[0], setInitialNodes = _d[1];
    var _e = useState([]), initialEdges = _e[0], setInitialEdges = _e[1];
    // NEW: track which phone numbers are currently assigned to this flow
    var _f = useState([]), initialAssignedNumbers = _f[0], setInitialAssignedNumbers = _f[1];
    var _g = useState(true), loading = _g[0], setLoading = _g[1];
    var _h = useState(null), errorMessage = _h[0], setErrorMessage = _h[1];
    var _j = useState(null), successMessage = _j[0], setSuccessMessage = _j[1];
    var navigate = useNavigate();
    useEffect(function () {
        if (isCreate) {
            // Creating new flow
            setLoading(false);
            return;
        }
        if (!flowId) {
            setLoading(false);
            return;
        }
        var loadFlow = function () { return __awaiter(_this, void 0, void 0, function () {
            var data, assignedIds, parsed, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, 3, 4]);
                        return [4 /*yield*/, getCallFlowById(flowId)];
                    case 1:
                        data = _a.sent();
                        if (!data) {
                            setErrorMessage("Flow not found");
                            setLoading(false);
                            return [2 /*return*/];
                        }
                        setFlowName(data.name || "");
                        // If your API returns already assigned numbers, store them so we can pre-check in the UI
                        if (Array.isArray(data.assignedPhoneNumbers)) {
                            assignedIds = data.assignedPhoneNumbers.map(function (num) { return num.id; });
                            setInitialAssignedNumbers(assignedIds);
                        }
                        if (data.flowJson) {
                            try {
                                parsed = JSON.parse(data.flowJson);
                                setInitialNodes(parsed.nodes || []);
                                setInitialEdges(parsed.edges || []);
                            }
                            catch (err) {
                                setErrorMessage("Invalid JSON in flowJson");
                            }
                        }
                        return [3 /*break*/, 4];
                    case 2:
                        error_1 = _a.sent();
                        setErrorMessage("Error loading flow");
                        return [3 /*break*/, 4];
                    case 3:
                        setLoading(false);
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        loadFlow();
    }, [isCreate, flowId]);
    var handleSaveFlow = function (flowData, assignedNumbers) { return __awaiter(_this, void 0, void 0, function () {
        var nodes, edges, currentFlowName, sanitizedNodes, flowJson, nameToSave, payload, savedFlowId, isNewFlow, newFlow, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    nodes = flowData.nodes, edges = flowData.edges, currentFlowName = flowData.currentFlowName;
                    sanitizedNodes = nodes.map(function (node) {
                        var _a = node.data, phoneNumbers = _a.phoneNumbers, agents = _a.agents, restData = __rest(_a, ["phoneNumbers", "agents"]);
                        return __assign(__assign({}, node), { data: restData });
                    });
                    flowJson = JSON.stringify({ nodes: sanitizedNodes, edges: edges });
                    nameToSave = currentFlowName || "Untitled Flow";
                    payload = { name: nameToSave, flowJson: flowJson };
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 8, , 9]);
                    savedFlowId = flowId || undefined;
                    isNewFlow = false;
                    if (!isCreate) return [3 /*break*/, 3];
                    return [4 /*yield*/, createCallFlow(payload)];
                case 2:
                    newFlow = _a.sent();
                    savedFlowId = newFlow.id; // Ensure the new ID is retrieved
                    isNewFlow = true;
                    return [3 /*break*/, 5];
                case 3:
                    if (!flowId) return [3 /*break*/, 5];
                    return [4 /*yield*/, updateCallFlow(flowId, payload)];
                case 4:
                    _a.sent();
                    _a.label = 5;
                case 5:
                    if (!(savedFlowId !== undefined)) return [3 /*break*/, 7];
                    return [4 /*yield*/, assignPhoneNumbersToCallFlow(savedFlowId, assignedNumbers)];
                case 6:
                    _a.sent();
                    // Store them in our local state as well, so the UI stays in sync
                    setInitialAssignedNumbers(assignedNumbers);
                    _a.label = 7;
                case 7:
                    setSuccessMessage("Flow saved successfully!");
                    // Navigate only if it's a new flow
                    if (isNewFlow) {
                        navigate("/call-flows/edit/".concat(savedFlowId));
                    }
                    return [3 /*break*/, 9];
                case 8:
                    err_1 = _a.sent();
                    setErrorMessage("Save failed. Check console for details.");
                    console.error(err_1);
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    if (loading) {
        return _jsx(FlowBuilderSkeleton, {});
    }
    return (_jsxs(_Fragment, { children: [_jsx(Snackbar, __assign({ variant: "soft", color: "danger", open: errorMessage !== null, onClose: function () { return setErrorMessage(null); }, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 6000, startDecorator: _jsx(ErrorIcon, {}), endDecorator: _jsx(Button, __assign({ onClick: function () { return setErrorMessage(null); }, size: "sm", variant: "soft", color: "danger" }, { children: "Dismiss" })) }, { children: errorMessage })), _jsx(Snackbar, __assign({ variant: "soft", color: "success", open: successMessage != null, onClose: function () { return setSuccessMessage(null); }, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 6000, startDecorator: _jsx(DoneIcon, {}), endDecorator: _jsx(Button, __assign({ onClick: function () { return setSuccessMessage(null); }, size: "sm", variant: "soft", color: "success" }, { children: "Dismiss" })) }, { children: successMessage })), _jsx(ReactFlowProvider, { children: _jsx(Box, __assign({ sx: { display: "flex", flexDirection: "column" } }, { children: _jsx(FlowCanvas, { initialNodes: initialNodes, initialEdges: initialEdges, currentFlowName: currentFlowName, onSave: handleSaveFlow, callFlowId: flowId ? flowId : undefined, 
                        /** IMPORTANT: Pass down any currently assigned numbers so the modal can show them checked. */
                        initialAssignedNumbers: initialAssignedNumbers }) })) })] }));
}
