var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Stack, IconButton } from "@mui/joy";
import { Tooltip, Typography } from "@mui/joy";
import { DataGrid, GridToolbar, GridActionsCellItem } from "@mui/x-data-grid";
import Chip from "@mui/joy/Chip";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinearProgress from "@mui/joy/LinearProgress";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { experimental_extendTheme as materialExtendTheme, Experimental_CssVarsProvider as MaterialCssVarsProvider, THEME_ID as MATERIAL_THEME_ID, } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
var materialTheme = materialExtendTheme();
var getColor = function (topic) {
    var hash = 0;
    for (var i = 0; i < topic.length; i++) {
        hash = topic.charCodeAt(i) + ((hash << 5) - hash);
    }
    var color = "hsl(".concat(hash % 360, ", 90%, 70%)");
    return color;
};
var getSentimentColor = function (value) {
    if (value < 0) {
        return "rgba(255, 0, 0, ".concat(-value, ")"); // More transparent as value approaches 0
    }
    else {
        return "rgba(0, 255, 0, ".concat(value, ")"); // More transparent as value approaches 0
    }
};
var handleViewSessionClick = function (params) { return function () {
    window.open(params.row.sessionUrl, '_blank', 'noopener,noreferrer');
}; };
var sessionColumns = [
    { field: "summary", headerName: "Summary", flex: 3 },
    { field: "resolutionDescription", headerName: "Resolution Notes", flex: 3 },
    {
        field: "topic",
        headerName: "Topic",
        flex: 2,
        align: "center",
        renderCell: function (params) { return (_jsx("div", __assign({ style: {
                display: "flex",
                flexWrap: "wrap",
                gap: "4px",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                textOverflow: "ellipsis",
            } }, { children: params.value && (_jsx(Tooltip, __assign({ title: params.value, placement: "top", arrow: true }, { children: _jsx(Chip, __assign({ variant: "outlined", size: "sm" }, { children: params.value })) }), params.value)) }))); },
    },
    {
        field: "sentimentScore",
        headerName: "Sentiment",
        flex: 1,
        renderCell: function (params) {
            return params.value && (_jsx(Tooltip, __assign({ title: "Sentiment Score: ".concat(params.value.toFixed(2), " (Range: -1 to 1)"), placement: "top", arrow: true }, { children: _jsxs(Box, __assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    } }, { children: [_jsx(Box, __assign({ sx: { width: "100%", mr: 1 } }, { children: _jsx(LinearProgress, { variant: "solid", determinate: true, value: params.value >= 0 ? params.value * 100 : -params.value * 100, sx: {
                                    backgroundColor: "#ddd",
                                    "&::before": {
                                        backgroundColor: getSentimentColor(params.value),
                                    },
                                } }) })), _jsx(Box, __assign({ sx: { minWidth: 35 } }, { children: params.value.toFixed(2) }))] })) })));
        },
    },
    {
        field: "isResolved",
        headerName: "Resolved",
        align: "center",
        type: 'boolean'
    },
    {
        field: "lackOfKnowledgeBase",
        headerName: "Knowledge Base Coverage",
        align: "center",
        type: 'boolean'
    },
    {
        field: "Session",
        type: 'actions',
        getActions: function (params) { return [
            _jsx(GridActionsCellItem, { icon: _jsx(OpenInNewIcon, {}), label: "View Session", onClick: handleViewSessionClick(params) }, params.id)
        ]; }
    },
];
var knowledgeBaseGapsColumns = [
    { field: "description", headerName: "Description", flex: 4 },
    {
        field: "missingTopics",
        headerName: "Missing Topics",
        flex: 3,
        renderCell: function (params) { return (_jsx("div", __assign({ style: {
                display: "flex",
                flexWrap: "wrap",
                gap: "4px",
                overflow: "hidden",
                textOverflow: "ellipsis",
            } }, { children: params.value.map(function (topic, index) { return (_jsx(Tooltip, __assign({ title: topic, placement: "top", arrow: true }, { children: _jsx(Chip, __assign({ variant: "outlined", size: "sm", sx: { backgroundColor: getColor(topic) } }, { children: topic })) }), index)); }) }))); },
    },
    {
        field: "Session",
        type: 'actions',
        getActions: function (params) { return [
            _jsx(GridActionsCellItem, { icon: _jsx(OpenInNewIcon, {}), label: "View Session", onClick: handleViewSessionClick(params) }, params.id)
        ]; }
    },
];
var sentimentColumns = [
    { field: "description", headerName: "Description", flex: 4 },
    {
        field: "sentimentScore",
        headerName: "Sentiment",
        flex: 1,
        renderCell: function (params) {
            return params.value && (_jsx(Tooltip, __assign({ title: "Sentiment Score: ".concat(params.value.toFixed(2), " (Range: -1 to 1)"), placement: "top", arrow: true }, { children: _jsxs(Box, __assign({ sx: {
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                    } }, { children: [_jsx(Box, __assign({ sx: { width: "100%", mr: 1 } }, { children: _jsx(LinearProgress, { variant: "solid", determinate: true, value: params.value >= 0 ? params.value * 100 : -params.value * 100, sx: {
                                    backgroundColor: "#ddd",
                                    "&::before": {
                                        backgroundColor: getSentimentColor(params.value),
                                    },
                                } }) })), _jsx(Box, __assign({ sx: { minWidth: 35 } }, { children: params.value.toFixed(2) }))] })) })));
        },
    },
    {
        field: "Session",
        type: 'actions',
        getActions: function (params) { return [
            _jsx(GridActionsCellItem, { icon: _jsx(OpenInNewIcon, {}), label: "View Session", onClick: handleViewSessionClick(params) }, params.id)
        ]; }
    },
];
var escalationColumns = [
    { field: "description", headerName: "Description", flex: 4 },
    {
        field: "Session",
        type: 'actions',
        getActions: function (params) { return [
            _jsx(GridActionsCellItem, { icon: _jsx(OpenInNewIcon, {}), label: "View Session", onClick: handleViewSessionClick(params) }, params.id)
        ]; }
    },
];
export var GridType;
(function (GridType) {
    GridType["SessionGrid"] = "sessionGrid";
    GridType["SentimentGrid"] = "sentimentGrid";
    GridType["KnowledgebaseGrid"] = "knowledgeBaseGrid";
    GridType["Escalations"] = "EscalationsGrid";
})(GridType || (GridType = {}));
var AnalyticsDataGrid = function (_a) {
    var _b;
    var onClose = _a.onClose, gridType = _a.gridType, data = _a.data;
    var isMdScreen = useMediaQuery(function (theme) {
        return theme.breakpoints.down("md");
    });
    var columns;
    var title;
    switch (gridType) {
        case GridType.SessionGrid:
            title = "Sessions";
            columns = sessionColumns;
            break;
        case GridType.SentimentGrid:
            title = "Sentiment";
            columns = sentimentColumns;
            break;
        case GridType.KnowledgebaseGrid:
            title = "Training Needed";
            columns = knowledgeBaseGapsColumns;
            break;
        case GridType.Escalations:
            title = "Escalations";
            columns = escalationColumns;
            break;
        default:
            columns = [];
    }
    return (_jsxs(Stack, __assign({ direction: "column", spacing: 1, sx: { width: '100%', height: '100%', mt: 1, overflowY: "none" } }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Typography, __assign({ level: "h2", component: "h1" }, { children: title })), _jsx(IconButton, __assign({ size: "sm", onClick: onClose }, { children: _jsx(CloseRoundedIcon, {}) }))] })), _jsx(MaterialCssVarsProvider, __assign({ theme: (_b = {}, _b[MATERIAL_THEME_ID] = materialTheme, _b) }, { children: _jsx(DataGrid, { rows: data, columns: columns, slots: { toolbar: GridToolbar }, slotProps: { toolbar: { csvOptions: { delimiter: ";" } } }, initialState: {
                        density: 'compact',
                    }, sx: {
                        "& .MuiDataGrid-cell": {
                            display: "flex",
                            alignItems: "center",
                        },
                        flex: 1,
                    }, autoHeight: false, disableDensitySelector: true, getRowHeight: function () { return 'auto'; }, getRowId: function (row) { return row.sessionId; } }) }))] })));
};
export default AnalyticsDataGrid;
