var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { Box, Card, CardContent, Typography, Select, Stack, Input, FormLabel, useColorScheme, } from "@mui/joy";
import Option from "@mui/joy/Option";
import Skeleton from "react-loading-skeleton";
import { Line, Pie, Chart } from "react-chartjs-2";
import { Chart as ChartJS, Tooltip, Legend, LinearScale, CategoryScale, BarElement, PointElement, LineElement, ArcElement } from "chart.js";
import { FunnelController, TrapezoidElement, } from "chartjs-chart-funnel";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { FilterEnums } from "../../types/enums";
import { useCallAnalytics } from "../../data/hooks/useCallAnalytics";
import "../CallAnalytics/styles/styles.css";
ChartJS.register(Tooltip, Legend, LinearScale, CategoryScale, BarElement, PointElement, LineElement, ArcElement);
ChartJS.register(FunnelController, TrapezoidElement, LinearScale, CategoryScale);
// Helper to show something like "01/31/2025 - 02/02/2025" in the dropdown
var displayRange = function (start, end) {
    if (!start || !end)
        return "Custom Range";
    var s = start.toLocaleDateString();
    var e = end.toLocaleDateString();
    return "".concat(s, " - ").concat(e);
};
export var CallAnalytics = function () {
    var _a = useCallAnalytics(), trendingChartData = _a.trendingChartData, isLoading = _a.isLoading, getTrendingChartData = _a.getTrendingChartData, funnelChartData = _a.funnelChartData, isLoadingFunnel = _a.isLoadingFunnel, getFunnelChartData = _a.getFunnelChartData, flows = _a.flows, getFlows = _a.getFlows, getCallEndReasonStats = _a.getCallEndReasonStats, endReasonStats = _a.endReasonStats, isLoadingEndReasonStats = _a.isLoadingEndReasonStats;
    var _b = useState(FilterEnums.last12Hours), dateRange = _b[0], setDateRange = _b[1];
    var mode = useColorScheme().mode;
    var _c = useState("hourly"), grouping = _c[0], setGrouping = _c[1];
    var _d = useState(undefined), selectedFlow = _d[0], setSelectedFlow = _d[1];
    var _e = useState(15), conversionCriteriaInSeconds = _e[0], setConversionCriteriaInSeconds = _e[1];
    // Store the actual selected custom range
    var _f = useState(null), customStartDate = _f[0], setCustomStartDate = _f[1];
    var _g = useState(null), customEndDate = _g[0], setCustomEndDate = _g[1];
    // For <DateRange /> we keep the 'ranges' array
    var _h = useState([
        {
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
        },
    ]), selectedRange = _h[0], setSelectedRange = _h[1];
    // Controls the visibility of the <DateRange> popup
    var _j = useState(false), showDateRangePicker = _j[0], setShowDateRangePicker = _j[1];
    // Outside click detection
    var dateRangeRef = useRef(null);
    useEffect(function () {
        function handleClickOutside(event) {
            if (dateRangeRef.current &&
                !dateRangeRef.current.contains(event.target)) {
                setShowDateRangePicker(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return function () {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    // Example: fetch flows on mount
    useEffect(function () {
        getFlows();
    }, []);
    // Example: re-fetch whenever relevant filters change
    useEffect(function () {
        getTrendingChartData(dateRange, grouping, selectedFlow, customStartDate, customEndDate);
    }, [dateRange, grouping, selectedFlow, customStartDate, customEndDate]);
    useEffect(function () {
        getFunnelChartData(dateRange, grouping, conversionCriteriaInSeconds, selectedFlow, customStartDate, customEndDate);
    }, [
        dateRange,
        grouping,
        conversionCriteriaInSeconds,
        selectedFlow,
        customStartDate,
        customEndDate,
    ]);
    useEffect(function () {
        getCallEndReasonStats(dateRange, selectedFlow, customStartDate, customEndDate);
    }, [dateRange, selectedFlow, customStartDate, customEndDate]);
    // For your line chart labeling
    var adjustLabelsToLocalTime = function (labels) {
        return labels.map(function (utcString) {
            var date = new Date(utcString);
            switch (grouping) {
                case "hourly":
                    return date.toLocaleString(undefined, {
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        hour12: true,
                    });
                case "daily":
                    return date.toLocaleString(undefined, {
                        month: "short",
                        day: "numeric",
                    });
                case "monthly":
                    return date.toLocaleString(undefined, {
                        month: "short",
                        year: "numeric",
                    });
                default:
                    return date.toLocaleString(undefined, {
                        dateStyle: "short",
                        timeStyle: "short",
                    });
            }
        });
    };
    return (_jsx(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%", overflowY: "auto" } }, { children: _jsxs(Box, __assign({ sx: { mt: 1, px: 2, width: "100%", height: "100%" } }, { children: [_jsx(Card, __assign({ sx: { marginBottom: 1 } }, { children: _jsx(CardContent, { children: _jsxs(Box, __assign({ display: "flex", gap: 1, flexWrap: "wrap" }, { children: [_jsxs(Select
                                // The current selected value in the dropdown
                                , __assign({ 
                                    // The current selected value in the dropdown
                                    value: dateRange, 
                                    // Called when user changes selection
                                    onChange: function (event, newValue) {
                                        if (!newValue)
                                            return;
                                        // If user switched from something else to custom
                                        setDateRange(newValue);
                                        if (newValue === FilterEnums.custom) {
                                            // They explicitly picked custom
                                            setShowDateRangePicker(true);
                                        }
                                        else {
                                            // They picked anything else
                                            setShowDateRangePicker(false);
                                        }
                                    }, 
                                    // We'll make it a bit wider
                                    sx: { minWidth: 200 }, placeholder: "Select Date Range", size: "sm" }, { children: [_jsx(Option, __assign({ value: FilterEnums.last30Minutes }, { children: "Last 30 Minutes" })), _jsx(Option, __assign({ value: FilterEnums.last1Hour }, { children: "Last Hour" })), _jsx(Option, __assign({ value: FilterEnums.last4Hours }, { children: "Last 4 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last12Hours }, { children: "Last 12 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last24Hours }, { children: "Last 24 Hours" })), _jsx(Option, __assign({ value: FilterEnums.last48Hours }, { children: "Last 48 Hours" })), _jsx(Option, __assign({ value: FilterEnums.today }, { children: "Today" })), _jsx(Option, __assign({ value: FilterEnums.yesterday }, { children: "Yesterday" })), _jsx(Option, __assign({ value: FilterEnums.last3Days }, { children: "Last 3 Days" })), _jsx(Option, __assign({ value: FilterEnums.last7Days }, { children: "Last 7 Days" })), _jsx(Option, __assign({ value: FilterEnums.last1Month }, { children: "Last 1 Month" })), _jsx(Option, __assign({ value: FilterEnums.last3Months }, { children: "Last 3 Months" })), _jsx(Option, __assign({ value: FilterEnums.all }, { children: "All" })), _jsx(Option, __assign({ value: FilterEnums.custom, onClick: function () {
                                                // If user *re-clicks* the same "Custom Range" while it's already selected,
                                                // onChange won't fire, so we manually open the date picker here:
                                                setDateRange(FilterEnums.custom);
                                                setShowDateRangePicker(true);
                                            } }, { children: displayRange(customStartDate, customEndDate) }))] })), _jsxs(Select, __assign({ size: "sm", value: grouping, onChange: function (e, newValue) { return setGrouping(newValue); }, placeholder: "Select Grouping", sx: { minWidth: 120 } }, { children: [_jsx(Option, __assign({ value: "hourly" }, { children: "Hourly" })), _jsx(Option, __assign({ value: "daily" }, { children: "Daily" })), _jsx(Option, __assign({ value: "monthly" }, { children: "Monthly" }))] })), _jsxs(Select, __assign({ size: "sm", value: selectedFlow || "", onChange: function (e, newValue) {
                                        return setSelectedFlow(newValue || undefined);
                                    }, placeholder: "Select Flow", sx: { minWidth: 150 } }, { children: [_jsx(Option, __assign({ value: "" }, { children: "All Agents" })), flows.map(function (flow) { return (_jsx(Option, __assign({ value: flow.flowId }, { children: flow.flowName }), flow.flowId)); })] }))] })) }) })), dateRange === FilterEnums.custom && showDateRangePicker && (_jsx(Box, __assign({ ref: dateRangeRef, sx: function (theme) { return ({
                        position: "absolute",
                        zIndex: 9999,
                        display: "inline-block",
                    }); } }, { children: _jsx(DateRange, { ranges: selectedRange, onChange: function (item) {
                            setSelectedRange([item.selection]);
                            setCustomStartDate(item.selection.startDate);
                            setCustomEndDate(item.selection.endDate);
                        }, moveRangeOnFirstSelection: false, editableDateInputs: true, className: mode === "dark" ? "custom-date-range-picker" : "" }) }))), _jsx(Card, { children: _jsx(CardContent, { children: isLoading ? (_jsx(Skeleton, { height: 400 })) : trendingChartData ? (_jsx(Box, __assign({ height: 400 }, { children: _jsx(Line, { data: {
                                    labels: adjustLabelsToLocalTime(trendingChartData.labels),
                                    datasets: trendingChartData.datasets.map(function (ds) { return (__assign(__assign({}, ds), { fill: false, tension: 0.4, borderWidth: 2, pointRadius: 4 })); }),
                                }, options: {
                                    responsive: true,
                                    maintainAspectRatio: false,
                                    plugins: {
                                        legend: { display: true },
                                        tooltip: { mode: "index", intersect: false },
                                    },
                                    scales: {
                                        x: { display: true },
                                        y: { beginAtZero: true },
                                    },
                                } }) }))) : (_jsx(Typography, { children: "No data available" })) }) }), _jsx(Card, __assign({ sx: {
                        marginTop: 1,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                    } }, { children: _jsxs(CardContent, __assign({ sx: { width: "100%" } }, { children: [_jsx(Typography, __assign({ level: "h3", sx: { mb: 2 } }, { children: "Call End Reasons" })), isLoadingEndReasonStats ? (_jsx(Skeleton, { height: 400 })) : endReasonStats ? (_jsx(Box, __assign({ sx: {
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    width: "100%",
                                } }, { children: _jsx(Box, __assign({ sx: { width: "60%", maxWidth: 600, height: 600 } }, { children: _jsx(Pie, { data: {
                                            labels: endReasonStats.labels,
                                            datasets: endReasonStats.datasets.map(function (dataset) { return (__assign(__assign({}, dataset), { borderWidth: 2 })); }),
                                        }, options: {
                                            responsive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                legend: { display: true, position: "top" },
                                                tooltip: { enabled: true },
                                                datalabels: {
                                                    formatter: function (value, context) {
                                                        var dataset = context.chart.data.datasets[0];
                                                        var total = dataset.data
                                                            .filter(function (val) { return typeof val === "number"; }) // Filter out non-numeric values
                                                            .reduce(function (acc, val) { return acc + val; }, 0); // Sum only numeric values
                                                        var percentage = total > 0
                                                            ? ((value / total) * 100).toFixed(1) + "%"
                                                            : "0%";
                                                        return percentage;
                                                    },
                                                    color: "#fff",
                                                    font: { weight: "bold" },
                                                },
                                            },
                                        } }) })) }))) : (_jsx(Typography, { children: "No data available" }))] })) })), _jsx(Card, __assign({ sx: { marginTop: 1 } }, { children: _jsxs(CardContent, { children: [_jsx(Typography, __assign({ level: "h3", sx: { mb: 2 } }, { children: "Call conversion" })), _jsxs(Box, __assign({ display: "flex", gap: 1, sx: { marginTop: 1 } }, { children: [_jsx(FormLabel, { children: "Lead locked after seconds" }), _jsx(Input, { value: conversionCriteriaInSeconds, type: "number", onChange: function (event) {
                                            return setConversionCriteriaInSeconds(Number(event.target.value));
                                        } })] })), isLoadingFunnel ? (_jsx(Skeleton, { height: 400 })) : funnelChartData && funnelChartData.length > 0 ? (_jsx(Box, __assign({ sx: {
                                    display: "grid",
                                    gap: 2,
                                    gridTemplateColumns: {
                                        xs: "1fr",
                                        sm: "1fr",
                                        md: "repeat(4, 1fr)",
                                    },
                                } }, { children: funnelChartData.map(function (item, index) {
                                    var dateObj = new Date(item.timePeriod);
                                    var localDateStr = dateObj.toLocaleString(undefined, {
                                        month: "short",
                                        day: "numeric",
                                        year: "numeric",
                                        hour: grouping === "hourly" ? "numeric" : undefined,
                                        hour12: grouping === "hourly",
                                    });
                                    var funnelData = {
                                        labels: ["Calls", "Transferred", "Converted"],
                                        datasets: [
                                            {
                                                label: "Funnel: ".concat(localDateStr),
                                                data: [
                                                    item.callCount,
                                                    item.transferredCount,
                                                    item.convertedCount,
                                                ],
                                                datalabels: {
                                                    formatter: function (v) { return v.toLocaleString(); },
                                                },
                                                backgroundColor: [
                                                    "rgba(255, 99, 132, 0.7)",
                                                    "rgba(54, 162, 235, 0.7)",
                                                    "rgba(75, 192, 75, 0.7)",
                                                ],
                                            },
                                        ],
                                    };
                                    var funnelOptions = {
                                        indexAxis: "y",
                                    };
                                    return (_jsxs(Box, __assign({ sx: { textAlign: "center" } }, { children: [_jsx(Typography, __assign({ level: "body-sm", sx: { mb: 1 } }, { children: localDateStr })), _jsx(Chart, { type: "funnel", data: funnelData, options: funnelOptions })] }), index));
                                }) }))) : (_jsx(Typography, { children: "No call conversion data available" }))] }) }))] })) })));
};
