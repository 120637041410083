var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from "react";
import { Modal, ModalDialog, Typography, Checkbox, List, ListItem, ListItemButton, ListItemDecorator, Button, Snackbar, Link, } from "@mui/joy";
import ErrorIcon from "@mui/icons-material/Error";
export default function AssignPhoneNumbersModal(_a) {
    var open = _a.open, onClose = _a.onClose, phoneNumbers = _a.phoneNumbers, selectedNumbers = _a.selectedNumbers, onSelectNumbers = _a.onSelectNumbers;
    var _b = useState([]), localSelected = _b[0], setLocalSelected = _b[1];
    var _c = useState(null), errorMessage = _c[0], setErrorMessage = _c[1];
    // When modal opens, initialize local selection from parent
    useEffect(function () {
        if (open) {
            setLocalSelected(selectedNumbers);
            setErrorMessage(null);
        }
    }, [open, selectedNumbers]);
    var handleToggle = function (numberId) {
        setLocalSelected(function (prev) {
            return prev.includes(numberId)
                ? prev.filter(function (id) { return id !== numberId; })
                : __spreadArray(__spreadArray([], prev, true), [numberId], false);
        });
    };
    var handleConfirmSelection = function () {
        onSelectNumbers(localSelected);
        onClose();
    };
    return (_jsxs(_Fragment, { children: [_jsx(Snackbar, __assign({ variant: "soft", color: "danger", open: !!errorMessage, onClose: function () { return setErrorMessage(null); }, autoHideDuration: 6000, startDecorator: _jsx(ErrorIcon, {}) }, { children: errorMessage })), _jsx(Modal, __assign({ open: open, onClose: onClose, hideBackdrop: true }, { children: _jsxs(ModalDialog, { children: [_jsx(Typography, __assign({ level: "title-lg", sx: { mb: 2 } }, { children: "Assign Phone Numbers," })), phoneNumbers.length === 0 ? (_jsxs(Typography, { children: ["No phone numbers found.", " ", "Buy phone numbers ", " ", _jsx(Link, __assign({ href: "/manage-phone-numbers" }, { children: "here" }))] })) : (_jsx(List, { children: phoneNumbers.map(function (num) {
                                var checked = localSelected.includes(num.id);
                                return (_jsx(ListItem, { children: _jsxs(ListItemButton, __assign({ onClick: function () { return handleToggle(num.id); } }, { children: [_jsx(ListItemDecorator, { children: _jsx(Checkbox, { checked: checked }) }), _jsx(Typography, { children: num.friendlyName })] })) }, num.id));
                            }) })), _jsx(Button, __assign({ sx: { mt: 2 }, onClick: handleConfirmSelection }, { children: "Confirm Selection" }))] }) }))] }));
}
