var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Skeleton } from "@mui/joy";
export default function FlowBuilderSkeleton() {
    return (_jsxs(Box, __assign({ sx: { display: "flex", height: "100vh", overflow: "hidden" } }, { children: [_jsxs(Box, __assign({ sx: {
                    width: "250px",
                    height: "100vh",
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    position: "fixed",
                    zIndex: 1000,
                } }, { children: [_jsx(Skeleton, { variant: "rectangular", width: 150, height: 30, sx: { mb: 2 } }), _jsx(Skeleton, { variant: "rectangular", width: 200, height: 40, sx: { mb: 1 } }), _jsx(Skeleton, { variant: "rectangular", width: 200, height: 40, sx: { mb: 1 } }), _jsx(Skeleton, { variant: "rectangular", width: 200, height: 40 })] })), _jsxs(Box, __assign({ sx: { flexGrow: 1, marginLeft: "250px", width: "calc(100% - 250px)" } }, { children: [_jsxs(Box, __assign({ sx: {
                            width: "100%",
                            height: "60px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            paddingX: 3,
                            position: "sticky",
                            top: 0,
                            zIndex: 900,
                            borderBottom: "1px solid #ddd",
                        } }, { children: [_jsx(Skeleton, { variant: "rectangular", width: "30%", height: 40 }), _jsx(Skeleton, { variant: "rectangular", width: 80, height: 40 })] })), _jsx(Box, __assign({ sx: {
                            width: "100%",
                            height: "calc(100vh - 60px)",
                            padding: 3,
                        } }, { children: _jsx(Skeleton, { variant: "rectangular", width: "100%", height: "100%" }) }))] }))] })));
}
