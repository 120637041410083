var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Box, Button, Modal, ModalDialog, Stack, Typography, Dropdown, MenuButton, Menu, MenuItem, Textarea, ModalClose, Select, Option, Alert, Input, Divider, IconButton, Skeleton, } from "@mui/joy";
import { useColorScheme } from "@mui/joy/styles";
import MoreVert from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Editor from "@monaco-editor/react";
import { useAzureFunctions } from "../../data/hooks/useAzureFunctions";
import { useRequest } from "../../hooks/useRequest";
var ActionDropdown = function (_a) {
    var functionName = _a.functionName, onDelete = _a.onDelete, onEdit = _a.onEdit;
    return (_jsxs(Dropdown, { children: [_jsx(MenuButton, __assign({ slots: { root: IconButton }, slotProps: { root: { variant: "plain", color: "neutral" } } }, { children: _jsx(MoreVert, {}) })), _jsxs(Menu, __assign({ placement: "bottom-end", size: "sm" }, { children: [_jsxs(MenuItem, __assign({ onClick: function () { return onEdit(functionName); } }, { children: [_jsx(EditIcon, { sx: { mr: 1 } }), "Edit"] })), _jsxs(MenuItem, __assign({ onClick: function () { return onDelete(functionName); }, variant: "soft", color: "danger" }, { children: [_jsx(DeleteIcon, { sx: { mr: 1 } }), "Delete"] }))] }))] }));
};
export var ManageAzureFunctions = function () {
    var mode = useColorScheme().mode;
    var _a = useState(true), darkMode = _a[0], setDarkMode = _a[1];
    var _b = useAzureFunctions(), azureFunctions = _b.azureFunctions, getFunctions = _b.getFunctions, getFunction = _b.getFunction, createOrUpdateFunction = _b.createOrUpdateFunction, deleteFunction = _b.deleteFunction;
    var _c = useRequest(), get = _c.get, post = _c.post, put = _c.put;
    // For the modal (create or edit)
    var _d = useState(false), openModal = _d[0], setOpenModal = _d[1];
    var _e = useState(false), isEditing = _e[0], setIsEditing = _e[1];
    // We'll store the entire function detail in a single state
    var _f = useState(null), functionData = _f[0], setFunctionData = _f[1];
    // For alerts
    var _g = useState(null), errorMessage = _g[0], setErrorMessage = _g[1];
    var _h = useState(null), successMessage = _h[0], setSuccessMessage = _h[1];
    // For the test panel inside the modal
    var _j = useState("GET"), testMethod = _j[0], setTestMethod = _j[1];
    var _k = useState('[{"name":"","value":""}]'), queryParams = _k[0], setQueryParams = _k[1];
    var _l = useState('[{"name":"","value":""}]'), headers = _l[0], setHeaders = _l[1];
    var _m = useState("{}"), requestBody = _m[0], setRequestBody = _m[1];
    var _o = useState(null), testResponse = _o[0], setTestResponse = _o[1];
    var _p = useState(true), loading = _p[0], setLoading = _p[1]; // Loading state
    var defaultCodeSnippet = "\nmodule.exports = async function (context, req) {\n  context.log('JavaScript HTTP trigger function processed a request.');\n\n  const name = (req.query.name || (req.body && req.body.name));\n  const responseMessage = name\n      ? \"Hello, \" + name + \". This HTTP triggered function executed successfully.\"\n      : \"This HTTP triggered function executed successfully. Pass a name in the query string or in the request body for a personalized response.\";\n\n  context.res = {\n      body: responseMessage\n  };\n};\n";
    useEffect(function () {
        // Fetch Azure Functions
        setLoading(true);
        getFunctions().finally(function () { return setLoading(false); }); // End loading after fetch
    }, []);
    useEffect(function () {
        setDarkMode(mode === "dark");
    }, [mode]);
    var handleDelete = function (fnName) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!window.confirm("Delete function '".concat(fnName, "'?"))) return [3 /*break*/, 2];
                    return [4 /*yield*/, deleteFunction(fnName)];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleEdit = function (fnName) { return __awaiter(void 0, void 0, void 0, function () {
        var data, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsEditing(true);
                    setErrorMessage(null);
                    setSuccessMessage(null);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getFunction(fnName)];
                case 2:
                    data = _a.sent();
                    // data has { name, apiUrl, apiKey, script }
                    setFunctionData(data);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    console.error(err_1);
                    setErrorMessage("Error loading function details.");
                    return [3 /*break*/, 4];
                case 4:
                    setOpenModal(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleCreateNew = function () {
        // Clear states for new function creation
        setIsEditing(false);
        setFunctionData({
            name: "",
            script: defaultCodeSnippet,
            apiUrl: "",
            apiKey: "",
        });
        setErrorMessage(null);
        setSuccessMessage(null);
        setOpenModal(true);
    };
    var handleDeploy = function () { return __awaiter(void 0, void 0, void 0, function () {
        var fnName, fnCode, updatedData, _a, err_2;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setErrorMessage(null);
                    setSuccessMessage(null);
                    fnName = ((functionData === null || functionData === void 0 ? void 0 : functionData.name) || "").trim();
                    fnCode = ((functionData === null || functionData === void 0 ? void 0 : functionData.script) || "").trim();
                    if (!fnName) {
                        setErrorMessage("Function name cannot be empty.");
                        return [2 /*return*/];
                    }
                    if (!fnCode) {
                        setErrorMessage("Function code cannot be empty.");
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 7, , 8]);
                    return [4 /*yield*/, createOrUpdateFunction({
                            functionName: fnName,
                            functionCode: fnCode,
                        })];
                case 2:
                    _b.sent();
                    setSuccessMessage("Function \"".concat(fnName, "\" deployed successfully."));
                    _b.label = 3;
                case 3:
                    _b.trys.push([3, 5, , 6]);
                    return [4 /*yield*/, getFunction(fnName)];
                case 4:
                    updatedData = _b.sent();
                    setFunctionData(updatedData);
                    return [3 /*break*/, 6];
                case 5:
                    _a = _b.sent();
                    return [3 /*break*/, 6];
                case 6: return [3 /*break*/, 8];
                case 7:
                    err_2 = _b.sent();
                    setErrorMessage("Error deploying function: " + err_2.message);
                    return [3 /*break*/, 8];
                case 8: return [2 /*return*/];
            }
        });
    }); };
    /**
     * Copy function URL or API Key to clipboard
     */
    var copyFunctionUrl = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(functionData === null || functionData === void 0 ? void 0 : functionData.apiUrl)) return [3 /*break*/, 2];
                    return [4 /*yield*/, navigator.clipboard.writeText(functionData.apiUrl)];
                case 1:
                    _a.sent();
                    setSuccessMessage("Function URL copied to clipboard.");
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var copyApiKey = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(functionData === null || functionData === void 0 ? void 0 : functionData.apiKey)) return [3 /*break*/, 2];
                    return [4 /*yield*/, navigator.clipboard.writeText(functionData.apiKey)];
                case 1:
                    _a.sent();
                    setSuccessMessage("API Key copied to clipboard.");
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleRunTest = function () { return __awaiter(void 0, void 0, void 0, function () {
        var parsedQueryParams, url_1, parsedHeaders, config, response, error_1;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setTestResponse("Running...");
                    if (!(functionData === null || functionData === void 0 ? void 0 : functionData.apiUrl) || !(functionData === null || functionData === void 0 ? void 0 : functionData.apiKey)) {
                        setTestResponse("No function URL or API key available.");
                        return [2 /*return*/];
                    }
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 6, , 7]);
                    parsedQueryParams = JSON.parse(queryParams).reduce(function (acc, param) {
                        if (param.name && param.value) {
                            acc.push([param.name, param.value]);
                        }
                        return acc;
                    }, []);
                    url_1 = new URL(functionData.apiUrl);
                    parsedQueryParams.forEach(function (_a) {
                        var key = _a[0], value = _a[1];
                        url_1.searchParams.append(key, value);
                    });
                    parsedHeaders = JSON.parse(headers).reduce(function (acc, header) {
                        if (header.name && header.value) {
                            acc[header.name] = header.value;
                        }
                        return acc;
                    }, {});
                    config = {
                        headers: __assign({}, parsedHeaders),
                    };
                    response = void 0;
                    if (!(testMethod === "GET")) return [3 /*break*/, 3];
                    return [4 /*yield*/, get(url_1.toString(), undefined, config)];
                case 2:
                    response = _b.sent();
                    return [3 /*break*/, 5];
                case 3:
                    if (!(testMethod === "POST")) return [3 /*break*/, 5];
                    return [4 /*yield*/, post(url_1.toString(), JSON.parse(requestBody), config)];
                case 4:
                    response = _b.sent();
                    _b.label = 5;
                case 5:
                    // Handle response
                    if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
                        setTestResponse(JSON.stringify(response.data, null, 2));
                    }
                    else {
                        setTestResponse("Error: ".concat(response === null || response === void 0 ? void 0 : response.status, " - ").concat(response === null || response === void 0 ? void 0 : response.statusText));
                    }
                    return [3 /*break*/, 7];
                case 6:
                    error_1 = _b.sent();
                    setTestResponse("Error: ".concat(((_a = error_1.response) === null || _a === void 0 ? void 0 : _a.status) || 500, " - ").concat(error_1.message));
                    return [3 /*break*/, 7];
                case 7: return [2 /*return*/];
            }
        });
    }); };
    var defaultColDef = useMemo(function () {
        return {
            filter: true,
            resizable: true,
        };
    }, []);
    var columnDefs = [
        {
            headerName: "Function Name",
            field: "name",
            flex: 2,
        },
        {
            headerName: "Function App Url",
            field: "apiUrl",
            flex: 2,
        },
        {
            headerName: "Actions",
            field: "actions",
            sortable: false,
            filter: false,
            width: 100,
            maxWidth: 100,
            cellRenderer: function (params) { return (_jsx(Box, __assign({ sx: {
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                    justifyContent: "center",
                } }, { children: _jsx(ActionDropdown, { functionName: params.data.name, onDelete: handleDelete, onEdit: handleEdit }) }))); },
        },
    ];
    return (_jsxs(Stack, __assign({ direction: "column", spacing: 2, sx: { width: "100%", height: "100%" } }, { children: [_jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center", sx: { px: 2 } }, { children: [_jsx(Typography, __assign({ sx: { mt: 2 }, level: "h2", component: "h1" }, { children: "Manage Functions" })), _jsx(Button, __assign({ startDecorator: _jsx(AddIcon, {}), onClick: handleCreateNew, variant: "solid", color: "primary", sx: { mt: "6" } }, { children: "Create New Function" }))] })), _jsx(Box, __assign({ sx: { mt: 1, px: 2, width: "100%", height: "100%" } }, { children: loading ? (_jsx(Box, __assign({ sx: {
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: 2,
                        padding: 2,
                    } }, { children: __spreadArray([], Array(15), true).map(function (_, index) { return (_jsx(Box, __assign({ sx: { display: "flex", flexDirection: "column", gap: 1 } }, { children: _jsx(Skeleton, { variant: "rectangular", height: 30 }) }), index)); }) }))) : (_jsx(Box, __assign({ sx: { width: "100%", height: "100%" }, className: !darkMode ? "ag-theme-quartz" : "ag-theme-quartz-dark" }, { children: _jsx(AgGridReact, { rowData: azureFunctions, columnDefs: columnDefs, pagination: true, defaultColDef: defaultColDef, enableCellTextSelection: true }) }))) })), _jsx(Modal, __assign({ sx: {
                    zIndex: 9999999, // Ensure this is HIGHER than your sidebar z-index
                }, open: openModal, onClose: function () { return setOpenModal(false); }, hideBackdrop: true }, { children: _jsxs(ModalDialog, __assign({ "aria-labelledby": "edit-code-modal", variant: "outlined", size: "lg", layout: "center", sx: {
                        maxWidth: "80vw",
                        minWidth: "60vw",
                        maxHeight: "90vh",
                        overflow: "auto",
                    } }, { children: [_jsx(ModalClose, {}), _jsx(Typography, __assign({ id: "edit-code-modal", level: "h4", mb: 1 }, { children: isEditing ? "Edit Function" : "Create Function" })), errorMessage && (_jsx(Alert, __assign({ variant: "soft", color: "danger", endDecorator: _jsx(Button, __assign({ variant: "plain", onClick: function () { return setErrorMessage(null); }, size: "sm" }, { children: _jsx(CloseRoundedIcon, {}) })), sx: { mb: 2 } }, { children: errorMessage }))), successMessage && (_jsx(Alert, __assign({ variant: "soft", color: "success", endDecorator: _jsx(Button, __assign({ variant: "plain", onClick: function () { return setSuccessMessage(null); }, size: "sm" }, { children: _jsx(CloseRoundedIcon, {}) })), sx: { mb: 2 } }, { children: successMessage }))), _jsxs(Stack, __assign({ spacing: 2, direction: "row", alignItems: "flex-start", sx: { height: "100%" } }, { children: [_jsxs(Box, __assign({ sx: { width: "65%", height: "100%", overflowY: "auto" } }, { children: [_jsx(Input, { placeholder: "Function Name", value: (functionData === null || functionData === void 0 ? void 0 : functionData.name) || "", disabled: isEditing || !!(functionData === null || functionData === void 0 ? void 0 : functionData.apiUrl), onChange: function (e) {
                                                return setFunctionData(function (prev) { return (__assign(__assign({}, prev), { name: e.target.value })); });
                                            }, sx: { mb: 1 } }), _jsx(Box, __assign({ sx: { height: "500px" } }, { children: _jsx(Editor, { height: "100%", defaultLanguage: "javascript", value: (functionData === null || functionData === void 0 ? void 0 : functionData.script) || "", onChange: function (value) {
                                                    return setFunctionData(function (prev) { return (__assign(__assign({}, prev), { script: value || "" })); });
                                                }, theme: darkMode ? "vs-dark" : "light" }) })), _jsxs(Box, __assign({ sx: {
                                                mt: 2,
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                gap: 1,
                                            } }, { children: [_jsx(Button, __assign({ variant: "outlined", size: "sm", onClick: function () { return setOpenModal(false); } }, { children: "Cancel" })), _jsx(Button, __assign({ variant: "solid", onClick: handleDeploy }, { children: isEditing ? "Update" : "Create" })), " "] }))] })), _jsx(Divider, { orientation: "vertical" }), _jsxs(Box, __assign({ sx: {
                                        width: "35%",
                                        maxHeight: "80vh",
                                        overflowY: "auto",
                                        paddingRight: "8px", // Add padding for scroll bar visibility
                                    } }, { children: [isEditing || (functionData === null || functionData === void 0 ? void 0 : functionData.apiUrl) ? (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ level: "title-sm", mb: 1 }, { children: "Function Info" })), _jsxs(Stack, __assign({ spacing: 1, mb: 2 }, { children: [_jsx(Typography, __assign({ level: "body-sm" }, { children: "Function URL:" })), _jsx(Input, { readOnly: true, value: (functionData === null || functionData === void 0 ? void 0 : functionData.apiUrl) || "N/A" }), _jsx(Button, __assign({ size: "sm", variant: "soft", color: "neutral", startDecorator: _jsx(ContentCopyIcon, {}), onClick: copyFunctionUrl, disabled: !(functionData === null || functionData === void 0 ? void 0 : functionData.apiUrl) }, { children: "Copy Function URL" })), _jsxs(Typography, __assign({ level: "body-sm", mt: 2 }, { children: ["API Key (Use as ", _jsx("code", { children: "X-API-Key" }), " header for Auth):"] })), _jsx(Input, { readOnly: true, value: (functionData === null || functionData === void 0 ? void 0 : functionData.apiKey) || "N/A" }), _jsx(Button, __assign({ size: "sm", variant: "soft", color: "neutral", startDecorator: _jsx(ContentCopyIcon, {}), onClick: copyApiKey, disabled: !(functionData === null || functionData === void 0 ? void 0 : functionData.apiKey) }, { children: "Copy API Key" }))] })), _jsx(Divider, {})] })) : null, _jsx(Typography, __assign({ level: "title-sm", mt: 2, mb: 1 }, { children: "Test Function" })), _jsxs(Select, __assign({ placeholder: "HTTP Method", value: testMethod, slotProps: {
                                                listbox: {
                                                    sx: {
                                                        zIndex: 999999999, // Ensure dropdown is above the modal
                                                    },
                                                },
                                            }, onChange: function (event, newValue) { return setTestMethod(newValue || "GET"); } }, { children: [_jsx(Option, __assign({ value: "GET" }, { children: "GET" })), _jsx(Option, __assign({ value: "POST" }, { children: "POST" }))] })), _jsx(Typography, __assign({ level: "body-sm", mt: 1, mb: 1 }, { children: "Query Parameters" })), _jsx(Textarea, { size: "sm", placeholder: '[{"name": "param1", "value": "value1"}]', value: queryParams, onChange: function (e) { return setQueryParams(e.target.value); } }), _jsx(Typography, __assign({ level: "body-sm", mt: 1, mb: 1 }, { children: "Additional Headers" })), _jsx(Textarea, { size: "sm", placeholder: '[{"name": "header1", "value": "value1"}]', value: headers, onChange: function (e) { return setHeaders(e.target.value); } }), testMethod === "POST" && (_jsxs(_Fragment, { children: [_jsx(Typography, __assign({ level: "body-sm", mt: 1, mb: 1 }, { children: "Request Body" })), _jsx(Textarea, { size: "sm", minRows: 3, placeholder: '{"key": "value"}', value: requestBody, onChange: function (e) { return setRequestBody(e.target.value); } })] })), _jsx(Button, __assign({ onClick: handleRunTest, color: "neutral", variant: "soft", sx: { mt: 2 } }, { children: "Run Test" })), testResponse && (_jsxs(_Fragment, { children: [_jsx(Divider, { sx: { my: 2 } }), _jsx(Typography, __assign({ level: "body-sm" }, { children: "Response:" })), _jsx(Box, __assign({ sx: {
                                                        overflowY: "auto",
                                                        maxHeight: "200px",
                                                        mt: 1,
                                                        p: 1,
                                                        backgroundColor: "var(--joy-palette-background-level2)",
                                                    } }, { children: _jsx(Typography, { children: testResponse }) }))] }))] }))] }))] })) }))] })));
};
