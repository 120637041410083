var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useRef, useState, } from "react";
import { ReactFlow, addEdge, useNodesState, useEdgesState, Handle, Position, Controls, Background, BackgroundVariant, useReactFlow, ReactFlowProvider, } from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import Card from "@mui/joy/Card";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import Input from "@mui/joy/Input";
import Alert from "@mui/joy/Alert";
import DoneIcon from "@mui/icons-material/Done";
import ErrorIcon from "@mui/icons-material/Error";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Button, IconButton, Snackbar } from "@mui/joy";
import PhoneIcon from "@mui/icons-material/Phone";
import HubIcon from "@mui/icons-material/Hub";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import { Stack, useColorScheme } from "@mui/joy";
import { useFlows } from "../../../../../data/hooks/useFlows";
import { useCallFlows } from "../../../../../data/hooks/useCallFlows";
import AssignPhoneNumbersModal from "../AssignPhoneNumbersModal";
import { useNavigate } from "react-router-dom";
// Helper checks
function isRouterNode(node) {
    return (node === null || node === void 0 ? void 0 : node.type) === "routerNode";
}
function isAiAgentNode(node) {
    return (node === null || node === void 0 ? void 0 : node.type) === "aiAgentNode";
}
/** Node Palette */
function NodePalette(_a) {
    var nodes = _a.nodes;
    var onDragStart = useCallback(function (event, nodeType) {
        event.dataTransfer.setData("application/reactflow", nodeType);
        event.dataTransfer.effectAllowed = "move";
    }, [nodes]);
    return (_jsxs(Sheet, __assign({ sx: {
            position: { xs: "fixed", md: "sticky" },
            transform: {
                xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
                md: "none",
            },
            transition: "transform 0.4s, width 0.4s",
            zIndex: 10000,
            height: "100dvh",
            width: "var(--Sidebar-width)",
            top: 0,
            p: 2,
            flexShrink: 0,
            display: "flex",
            flexDirection: "column",
            gap: 1,
            borderRight: "1px solid",
            borderColor: "divider",
        } }, { children: [_jsx(Typography, __assign({ level: "title-lg", textColor: "text.secondary", component: "h1" }, { children: "Nodes" })), _jsx(Card, __assign({ draggable: true, onDragStart: function (e) { return onDragStart(e, "inboundCallNode"); }, variant: "soft", size: "sm", color: "primary", sx: {
                    textAlign: "center",
                    cursor: "grab",
                    borderRadius: "sm",
                } }, { children: _jsx(Typography, __assign({ level: "body-sm", startDecorator: _jsx(PhoneIcon, {}) }, { children: "Inbound Call" })) })), _jsx(Card, __assign({ draggable: true, onDragStart: function (e) { return onDragStart(e, "routerNode"); }, variant: "soft", size: "sm", color: "warning", sx: {
                    textAlign: "center",
                    cursor: "grab",
                    borderRadius: "sm",
                } }, { children: _jsx(Typography, __assign({ level: "body-sm", startDecorator: _jsx(HubIcon, {}) }, { children: "Call Distribution Router" })) })), _jsx(Card, __assign({ draggable: true, onDragStart: function (e) { return onDragStart(e, "aiAgentNode"); }, variant: "soft", size: "sm", color: "success", sx: {
                    textAlign: "center",
                    cursor: "grab",
                    borderRadius: "sm",
                } }, { children: _jsx(Typography, __assign({ level: "body-sm", startDecorator: _jsx(SmartToyIcon, {}) }, { children: "AI Agent" })) }))] })));
}
/** Node components */
function inboundCallNode(_a) {
    var id = _a.id, data = _a.data;
    var phoneNumbers = data.phoneNumbers;
    return (_jsxs(Card, __assign({ variant: "soft", color: "primary", sx: { minWidth: 180, p: 1.5 } }, { children: [_jsx(Typography, __assign({ startDecorator: _jsx(PhoneIcon, {}) }, { children: "Inbound Call" })), _jsx(Box, __assign({ mt: 1 }, { children: phoneNumbers === null || phoneNumbers === void 0 ? void 0 : phoneNumbers.map(function (num) { return (_jsx(Typography, __assign({ level: "body-sm" }, { children: num.friendlyName }), num.id)); }) })), _jsx(Handle, { type: "source", position: Position.Bottom })] })));
}
function RouterNode(_a) {
    var id = _a.id, data = _a.data;
    var _b = data.routes, routes = _b === void 0 ? [] : _b, routesError = data.routesError, updateNodeData = data.updateNodeData;
    var handlePercentageChange = function (event, targetNodeId) {
        var newVal = parseFloat(event.target.value) || 0;
        var updatedRoutes = routes.map(function (r) {
            return r.targetNodeId === targetNodeId ? __assign(__assign({}, r), { percentage: newVal }) : r;
        });
        var total = updatedRoutes.reduce(function (sum, r) { return sum + r.percentage; }, 0);
        var error = total !== 100 ? "Sum of route percentages must be exactly 100%" : "";
        updateNodeData === null || updateNodeData === void 0 ? void 0 : updateNodeData(id, {
            routes: updatedRoutes,
            routesError: error,
        });
    };
    return (_jsxs(Card, __assign({ variant: "soft", color: "warning", sx: { minWidth: 200, p: 1.5 } }, { children: [_jsx(Typography, __assign({ startDecorator: _jsx(HubIcon, {}) }, { children: "Call Distribution Router" })), _jsx(Box, __assign({ mt: 1, sx: { fontSize: "0.9em" } }, { children: routes.length === 0 ? (_jsx(Typography, __assign({ sx: { fontStyle: "italic" } }, { children: "No connected AI Agents yet" }))) : (routes.map(function (route) { return (_jsxs(Box, __assign({ sx: { display: "flex", alignItems: "center", mb: 1 } }, { children: [_jsx(Typography, __assign({ sx: { mr: 1 } }, { children: route.agentName }), route.targetNodeId), _jsx(Input, { type: "number", size: "sm", value: route.percentage, onChange: function (e) { return handlePercentageChange(e, route.targetNodeId); }, sx: { width: 70, mr: 1 } }, route.targetNodeId), _jsx(Typography, { children: "%" })] }), route.targetNodeId)); })) })), routesError && (_jsx(Alert, __assign({ variant: "soft", color: "danger", sx: { mt: 1 } }, { children: routesError }))), _jsx(Handle, { type: "target", position: Position.Top }), _jsx(Handle, { type: "source", position: Position.Bottom })] })));
}
function AiAgentNode(_a) {
    var id = _a.id, data = _a.data;
    var agentId = data.agentId, agents = data.agents, onAiAgentChange = data.onAiAgentChange;
    var handleChange = function (newAgentId) {
        onAiAgentChange === null || onAiAgentChange === void 0 ? void 0 : onAiAgentChange(id, newAgentId);
    };
    return (_jsxs(Card, __assign({ variant: "soft", color: "success", sx: { minWidth: 180, p: 1.5 } }, { children: [_jsx(Typography, __assign({ startDecorator: _jsx(SmartToyIcon, {}) }, { children: "AI Agent" })), _jsx(Box, __assign({ mt: 1 }, { children: _jsx(Select, __assign({ size: "sm", value: agentId || "", onChange: function (_, val) { return val && handleChange(val); }, placeholder: "Select agent" }, { children: agents === null || agents === void 0 ? void 0 : agents.map(function (agent) { return (_jsx(Option, __assign({ value: agent.id }, { children: agent.name }), agent.id)); }) })) })), _jsx(Handle, { type: "target", position: Position.Top })] })));
}
/** Node types */
var nodeTypes = {
    inboundCallNode: inboundCallNode,
    routerNode: RouterNode,
    aiAgentNode: AiAgentNode,
};
/** FlowCanvas Component */
export default function FlowCanvas(_a) {
    var _this = this;
    var initialNodes = _a.initialNodes, initialEdges = _a.initialEdges, currentFlowName = _a.currentFlowName, onSave = _a.onSave, callFlowId = _a.callFlowId, initialAssignedNumbers = _a.initialAssignedNumbers;
    var mode = useColorScheme().mode;
    var screenToFlowPosition = useReactFlow().screenToFlowPosition;
    // Maintain the flow state
    var _b = useNodesState(initialNodes), nodes = _b[0], setNodes = _b[1], onNodesChange = _b[2];
    var _c = useEdgesState(initialEdges), edges = _c[0], setEdges = _c[1], onEdgesChange = _c[2];
    var _d = useState(currentFlowName), flowName = _d[0], setFlowName = _d[1];
    var _e = useState(null), errorMessage = _e[0], setErrorMessage = _e[1];
    var _f = useState(null), successMessage = _f[0], setSuccessMessage = _f[1];
    var _g = useState(false), assignModalOpen = _g[0], setAssignModalOpen = _g[1];
    // ============ PHONE NUMBERS FOR ASSIGNMENT =============
    // 1. store the phone IDs the user has chosen for this flow
    var _h = useState(initialAssignedNumbers), assignedNumbers = _h[0], setAssignedNumbers = _h[1];
    var _j = useState(false), isSaving = _j[0], setIsSaving = _j[1];
    var navigate = useNavigate();
    // 2. store the list of all phone numbers user can pick from
    //    (both "unassigned" globally and "already assigned to this flow").
    var _k = useState([]), allNumbers = _k[0], setAllNumbers = _k[1];
    // For Node data references
    var phoneNumbersRef = useRef([]);
    var aiAgentsRef = useRef([]);
    // Hooks for data fetching
    var getFlows = useFlows().getFlows;
    var _l = useCallFlows(), 
    // REPLACE THIS with a new function that returns "unassigned + assigned to current flow"
    // or do two calls and merge them, or however your back end is structured.
    getNotAssignedPhoneNumbers = _l.getNotAssignedPhoneNumbers, getAvailablePhoneNumbersForFlow = _l.getAvailablePhoneNumbersForFlow;
    var handleBack = function () {
        // Navigate to table page
        navigate("/call-flows");
    };
    // Load phone numbers + AI agent data
    useEffect(function () {
        function fetchData() {
            return __awaiter(this, void 0, void 0, function () {
                var numbers, assignedNumbers_1, numbers, assignedNumbers_2, agents, error_1;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 6, , 7]);
                            if (!callFlowId) return [3 /*break*/, 2];
                            return [4 /*yield*/, getAvailablePhoneNumbersForFlow(callFlowId)];
                        case 1:
                            numbers = _a.sent();
                            assignedNumbers_1 = numbers.filter(function (f) {
                                return initialAssignedNumbers.includes(f.id);
                            });
                            setAllNumbers(numbers || []);
                            phoneNumbersRef.current = assignedNumbers_1 || [];
                            return [3 /*break*/, 4];
                        case 2: return [4 /*yield*/, getNotAssignedPhoneNumbers()];
                        case 3:
                            numbers = _a.sent();
                            assignedNumbers_2 = numbers.filter(function (f) {
                                return initialAssignedNumbers.includes(f.id);
                            });
                            setAllNumbers(numbers || []);
                            phoneNumbersRef.current = assignedNumbers_2 || [];
                            _a.label = 4;
                        case 4: return [4 /*yield*/, getFlows()];
                        case 5:
                            agents = _a.sent();
                            aiAgentsRef.current = agents || [];
                            // Re-inject the node data once we've fetched phoneNumbersRef & aiAgentsRef
                            injectCallbacksAndData();
                            return [3 /*break*/, 7];
                        case 6:
                            error_1 = _a.sent();
                            setErrorMessage("Failed to fetch phone numbers or AI agents");
                            return [3 /*break*/, 7];
                        case 7: return [2 /*return*/];
                    }
                });
            });
        }
        fetchData();
    }, [callFlowId, initialAssignedNumbers]);
    // 3. Re-inject node callbacks & data
    var injectCallbacksAndData = useCallback(function () {
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                var newData = __assign(__assign({}, node.data), { updateNodeData: updateNodeData });
                if (node.type === "inboundCallNode") {
                    newData.phoneNumbers = phoneNumbersRef.current;
                }
                else if (node.type === "aiAgentNode") {
                    newData.agents = aiAgentsRef.current;
                    newData.onAiAgentChange = handleAiAgentChange;
                }
                else if (node.type === "routerNode") {
                    if (!newData.routes)
                        newData.routes = [];
                    if (!newData.routesError)
                        newData.routesError = "";
                }
                return __assign(__assign({}, node), { data: newData });
            });
        });
    }, [setNodes]);
    useEffect(function () {
        // whenever initialNodes changes from parent, or you want to re-inject
        injectCallbacksAndData();
    }, [initialNodes, injectCallbacksAndData]);
    /** AI agent changes */
    var handleAiAgentChange = useCallback(function (nodeId, newAgentId) {
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                if (node.id === nodeId) {
                    var selectedAgent = aiAgentsRef.current.find(function (agent) { return agent.id === newAgentId; });
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { agentId: newAgentId, agentName: (selectedAgent === null || selectedAgent === void 0 ? void 0 : selectedAgent.name) || "Unselected" }) });
                }
                return node;
            });
        });
        // Also update router routes that reference this AI node
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                if (isRouterNode(node)) {
                    var oldRoutes = node.data.routes || [];
                    var updatedRoutes = oldRoutes.map(function (route) {
                        var _a;
                        return route.targetNodeId === nodeId
                            ? __assign(__assign({}, route), { agentId: newAgentId, agentName: ((_a = aiAgentsRef.current.find(function (a) { return a.id === newAgentId; })) === null || _a === void 0 ? void 0 : _a.name) || "Unselected" }) : route;
                    });
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), { routes: updatedRoutes }) });
                }
                return node;
            });
        });
    }, [setNodes]);
    /** Generic node data updater */
    var updateNodeData = useCallback(function (nodeId, updates) {
        setNodes(function (prevNodes) {
            return prevNodes.map(function (node) {
                if (node.id === nodeId) {
                    return __assign(__assign({}, node), { data: __assign(__assign({}, node.data), updates) });
                }
                return node;
            });
        });
    }, [setNodes]);
    // ================== Edge + Connect Handling ===================
    var onConnect = useCallback(function (connection) {
        var source = connection.source, target = connection.target;
        if (!source || !target)
            return;
        // 1) First, find the source node
        var sourceNode = nodes.find(function (n) { return n.id === source; });
        // 2) IF the source node is "inboundCallNode", check if there's an existing outgoing edge
        if ((sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.type) === "inboundCallNode") {
            var alreadyHasEdge = edges.some(function (edge) { return edge.source === source; });
            if (alreadyHasEdge) {
                // Show error or block the connection
                setErrorMessage("Inbound Call Node can only have one outgoing connection.");
                return;
                // By returning early, we skip adding another edge.
            }
        }
        // If check passes, we can add the edge
        setEdges(function (eds) { return addEdge(connection, eds); });
        // Optionally: If the source is a Router and target is AI => update the router routes, etc.
        var targetNode = nodes.find(function (n) { return n.id === target; });
        // If source is Router and target is AI => add route
        if (isRouterNode(sourceNode) && isAiAgentNode(targetNode)) {
            var selectedAgentId_1 = (targetNode === null || targetNode === void 0 ? void 0 : targetNode.data.agentId) || "";
            var selectedAgent = aiAgentsRef.current.find(function (a) { return a.id === selectedAgentId_1; }) || {};
            var selectedAgentName_1 = selectedAgent.name || "Unselected";
            var routerRoutes = (sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.data.routes) || [];
            var existingRoute = routerRoutes.find(function (r) { return r.targetNodeId === (targetNode === null || targetNode === void 0 ? void 0 : targetNode.id); });
            if (!existingRoute) {
                var newRoute = {
                    targetNodeId: targetNode.id,
                    agentId: selectedAgentId_1,
                    agentName: selectedAgentName_1,
                    percentage: 0,
                };
                updateNodeData(sourceNode.id, {
                    routes: __spreadArray(__spreadArray([], routerRoutes, true), [newRoute], false),
                });
            }
            else {
                // update existing route
                var updatedRoutes = routerRoutes.map(function (r) {
                    return r.targetNodeId === (targetNode === null || targetNode === void 0 ? void 0 : targetNode.id)
                        ? __assign(__assign({}, r), { agentId: selectedAgentId_1, agentName: selectedAgentName_1 }) : r;
                });
                updateNodeData(sourceNode.id, { routes: updatedRoutes });
            }
        }
    }, [nodes, edges, setEdges, aiAgentsRef, updateNodeData]);
    var onEdgesDelete = useCallback(function (deletedEdges) {
        setEdges(function (prev) {
            var updated = __spreadArray([], prev, true);
            deletedEdges.forEach(function (edge) {
                updated = updated.filter(function (e) { return e.id !== edge.id; });
                var sourceNode = nodes.find(function (n) { return n.id === edge.source; });
                var targetNode = nodes.find(function (n) { return n.id === edge.target; });
                // If source is Router, target is AI => remove route
                if (isRouterNode(sourceNode) && isAiAgentNode(targetNode)) {
                    var oldRoutes = (sourceNode === null || sourceNode === void 0 ? void 0 : sourceNode.data.routes) || [];
                    var newRoutes = oldRoutes.filter(function (r) { return r.targetNodeId !== (targetNode === null || targetNode === void 0 ? void 0 : targetNode.id); });
                    updateNodeData(sourceNode.id, { routes: newRoutes });
                }
            });
            return updated;
        });
    }, [nodes, setEdges, updateNodeData]);
    // ================== Drag + Drop Handling ===================
    var onDrop = useCallback(function (event) {
        event.preventDefault();
        var nodeType = event.dataTransfer.getData("application/reactflow");
        if (!nodeType)
            return;
        if (nodeType === "inboundCallNode") {
            var existingPhoneNode = nodes.find(function (node) { return node.type === "inboundCallNode"; });
            if (existingPhoneNode) {
                setErrorMessage("Only one Inbound Call Node is allowed.");
                return;
            }
        }
        var position = screenToFlowPosition({
            x: event.clientX,
            y: event.clientY,
        });
        var newData = {
            updateNodeData: updateNodeData,
        };
        if (nodeType === "inboundCallNode") {
            newData.phoneNumbers = phoneNumbersRef.current;
        }
        else if (nodeType === "routerNode") {
            newData.routes = [];
            newData.routesError = "";
        }
        else if (nodeType === "aiAgentNode") {
            newData.agents = aiAgentsRef.current;
            newData.onAiAgentChange = handleAiAgentChange;
        }
        var newNode = {
            id: "".concat(nodeType, "-").concat(Date.now()),
            type: nodeType,
            position: position,
            data: newData,
        };
        setNodes(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newNode], false); });
    }, [screenToFlowPosition, setNodes, updateNodeData, handleAiAgentChange]);
    var onDragOver = useCallback(function (event) {
        event.preventDefault();
        event.dataTransfer.dropEffect = "move";
    }, []);
    // ================== FINAL SAVE ===================
    var handleSaveClick = function () { return __awaiter(_this, void 0, void 0, function () {
        var error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsSaving(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, 4, 5]);
                    return [4 /*yield*/, onSave({ nodes: nodes, edges: edges, currentFlowName: flowName }, assignedNumbers)];
                case 2:
                    _a.sent();
                    setSuccessMessage("Flow saved successfully!");
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    setErrorMessage("Failed to save flow.");
                    return [3 /*break*/, 5];
                case 4:
                    setIsSaving(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // ================== RENDER ===================
    return (_jsxs(_Fragment, { children: [_jsx(Snackbar, __assign({ variant: "soft", color: "danger", open: errorMessage !== null, onClose: function () { return setErrorMessage(null); }, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 6000, startDecorator: _jsx(ErrorIcon, {}), endDecorator: _jsx(Button, __assign({ onClick: function () { return setErrorMessage(null); }, size: "sm", variant: "soft", color: "danger" }, { children: "Dismiss" })) }, { children: errorMessage })), _jsx(Snackbar, __assign({ variant: "soft", color: "success", open: successMessage != null, onClose: function () { return setSuccessMessage(null); }, anchorOrigin: { vertical: "top", horizontal: "center" }, autoHideDuration: 6000, startDecorator: _jsx(DoneIcon, {}), endDecorator: _jsx(Button, __assign({ onClick: function () { return setSuccessMessage(null); }, size: "sm", variant: "soft", color: "success" }, { children: "Dismiss" })) }, { children: successMessage })), _jsxs(Box, __assign({ sx: { display: "flex", height: "100vh" } }, { children: [_jsx(Box, { children: _jsx(NodePalette, { nodes: nodes }) }), _jsxs(Box, __assign({ sx: {
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            minHeight: 0,
                        } }, { children: [_jsx(Box, __assign({ sx: {
                                    position: "sticky",
                                    zIndex: 2,
                                    bgcolor: "background.body",
                                    borderBottom: "1px solid",
                                    borderColor: "divider",
                                    p: 2,
                                } }, { children: _jsxs(Stack, __assign({ direction: "row", justifyContent: "space-between", alignItems: "center" }, { children: [_jsx(Input, { placeholder: "Flow Name", value: flowName, size: "md", onChange: function (e) { return setFlowName(e.target.value); }, sx: { width: "100%", maxWidth: 400 } }), _jsxs(Box, __assign({ sx: { display: "flex", gap: 2 } }, { children: [_jsx(Button, __assign({ variant: "outlined", color: "success", onClick: function () {
                                                        setAssignModalOpen(true);
                                                    } }, { children: "Assign Phone Numbers" })), _jsx(Button, __assign({ variant: "soft", loading: isSaving, onClick: handleSaveClick }, { children: "Save" })), _jsx(IconButton, __assign({ size: "sm", onClick: handleBack }, { children: _jsx(CloseRoundedIcon, {}) }))] }))] })) })), _jsx(AssignPhoneNumbersModal, { open: assignModalOpen, onClose: function () { return setAssignModalOpen(false); }, 
                                // Show allNumbers (both assigned to this flow + unassigned)
                                phoneNumbers: allNumbers, 
                                // The ones currently selected for this flow
                                selectedNumbers: assignedNumbers, onSelectNumbers: function (selected) {
                                    // User just confirmed, store locally
                                    setAssignedNumbers(selected);
                                } }), _jsx(Box, __assign({ sx: { flex: 1, minHeight: 0, position: "relative" } }, { children: _jsx(ReactFlowProvider, { children: _jsxs(ReactFlow, __assign({ nodes: nodes, edges: edges, nodeTypes: nodeTypes, onNodesChange: onNodesChange, onEdgesChange: onEdgesChange, onConnect: onConnect, onEdgesDelete: onEdgesDelete, onDrop: onDrop, onDragOver: onDragOver, fitView: true, style: { width: "100%", height: "100%" } }, { children: [_jsx(Controls, {}), _jsx(Background, { variant: BackgroundVariant.Dots, gap: 16, size: 1 })] })) }) }))] }))] }))] }));
}
